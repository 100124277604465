<div class="dialog-content">
  <div class="stickyHeader" [ngClass]="isStickyHeaderVisible ? 'notificationStickyHeader':'' ">
    <div class="headerContentWrapper">
      <div class="modalCrossBtn" (click)="closeNotificationDialog()">
        <!-- <img src="/assets/new-dash/cross.svg" alt="Close button" (click)="closeNotificationDialog()" /> -->
        <mat-icon class="actionIcon">arrow_back</mat-icon>
      </div>
      <h3 class="title">
        {{title}}
      </h3>
    </div>
  </div>
  <div class="mainContent">
    <div class="imgBox">
      <figure>
        <img src="../../../../../assets/new-dash/innerpages/infoIcon.svg" alt="Notification Detail Icon"
          aria-labelledby="notificationDetailModal">
        <figcaption id="notificationDetailModal">
          {{title}}
        </figcaption>
      </figure>
      <div class="dateTime">
        {{ dateTime }}
      </div>
    </div>

    <div class="infoContentBox">
      <p class="content" [innerHTML]="subtitleContent">
        <!-- {{ subtitleContent }} -->
      </p>
    </div>

  </div>

  <!-- <div class="actionBtns">
    <button class="primary-general-btn" mat-dialog-close matRipple (click)="openAgreement()">
      Review the Updated Agreement
    </button>
  </div> -->
  <!-- <div class="actionBtns">
    <button class="primary-general-btn" (click)="closeNotificationDialog()" mat-dialog-close matRipple>
     Close
    </button>
  </div> -->
</div>