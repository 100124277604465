import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';


@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  private useSecureStorage = Capacitor.isNativePlatform(); // Use secure storage on mobile

  constructor() {}

  async saveAuthToken(key: string, value: string) {
    if (this.useSecureStorage) {
      await SecureStoragePlugin.set({ key: key, value: value });
    } else {
      await Preferences.set({ key: key, value: value });
    }
  }

  async getAuthToken(key: string): Promise<string | null> {
    if (this.useSecureStorage) {
      const { value } = await SecureStoragePlugin.get({ key: key }).catch(() => ({ value: null }));
      return value;
    } else {
      const { value } = await Preferences.get({ key: key });
      return value;
    }
  }

  //Remove Token 
  async removeAuthToken(key: string) {
    if (this.useSecureStorage) {
      await SecureStoragePlugin.remove({ key: key });
    } else {
      await Preferences.remove({ key: key });
    }
  }
}
