import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { AccountService } from 'src/app/core/service/account.service';
import { BrowserLogger } from 'src/app/core/browser-logger.service';
import { RumLogger } from 'src/app/core/rum-logger.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AuthStorageService } from '../auth/auth-storage.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit,OnDestroy {

  // email = new FormControl('', [Validators.required, Validators.email]);
  // password = new FormControl('', [Validators.required]);
  initiateLogin: boolean = false;
  fieldTextType: boolean = false;
  loggedIn = false;
  isEmailFocussed = false;
  isPwdFocussed = false;
  loginError: boolean = false;
  modalRef!: BsModalRef;
  failedLogin = false;
  loginForm: FormGroup;

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  // Initialize the form property  loginFormGroup: FormGroup = new FormGroup({}); 

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public authService: AuthenticationService,
    private cookieService: CookieService,
    public accountService: AccountService,
    private modalService: BsModalService,
    private authStorage: AuthStorageService,
    public activatedRoute: ActivatedRoute
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }



  BrowserLoggerObj :any = null;
  RumLoggerObj:any = null;

  ngOnInit() {
    this.authService.loggedInSubject.next(false);
    this.authService.loggedIn$.subscribe((status: boolean) => {
      this.loggedIn = status;
    });
    this.authService.loginError.subscribe((res) => {
      this.loggedIn = false;
      if (res === true) {
        // this.modalService.hide(100);
        // const initialState = {
        //   title: 'Login Error',
        //   message: !window.localStorage.getItem("loginError") ? 'Username and/or password incorrect' : window.localStorage.getItem("loginError")
        // };
        this.loginError = true;
        // window.localStorage.removeItem("loginError");
        // this.authService.loginError.next(false);
        // this.openModal(initialState);
      }
    })
  }


  getEmailErrorMessage() {
    // console.log("I am here");
    if (this.email?.hasError('required')) {
      return '*Email address is required';
    }

    return this.email?.hasError('email') ? 'Email address is invalid' : '';
  }
  getPwdErrorMessage() {
    if (this.password?.hasError('required')) {
      return '*Password is required';
    }
    return this.password?.hasError('password') ? 'Password is invalid' : '';
  }
  emailOnFocus() {
    this.isEmailFocussed = true;
  }
  emailOnFocusOut() {
    this.isEmailFocussed = false;
  }
  pwdOnFocus() {
    this.isPwdFocussed = true;
  }
  pwdOnFocusOut() {
    this.isPwdFocussed = false;
  }
  get email() {
    return this.loginForm.get('email')
  }
  get password() {
    return this.loginForm.get('password')
  }
  login(res:any) {

    // if (this.loginForm.valid) {
      // this.loggedIn = true;

      //todo
      const params = this.activatedRoute.snapshot.queryParams;
      const redirectURL = params['redirectURL'];
      const platformName = window.localStorage.getItem('platform') || 'browser';
      this.failedLogin = false;

      // this.authService.appLogin(this.loginForm, this.failedLogin, this.loggedIn, redirectURL)

      // this.authService.applogin(this.loginForm).subscribe({
        // next: (res: any) => {
          window.localStorage.setItem("token", JSON.stringify(res));
          this.authService.getConfig().subscribe({
            next: (configRes: any) => {
              window.localStorage.setItem("loginError", '');
              window.localStorage.setItem('KYC_IFRAME_URL', configRes['KYC_IFRAME_URL']);
              window.localStorage.setItem("ENABLE_GLOBAL_HTTP_INTERCEPTOR", configRes["ENABLE_GLOBAL_HTTP_INTERCEPTOR"]);
              window.localStorage.setItem("DATADOG_OPTION_SAMPLE_RATE", configRes["DATADOG_OPTION_SAMPLE_RATE"]);
              window.localStorage.setItem("ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING", configRes["ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING"]);
              window.localStorage.setItem("DATADOG_HOST_NAME", configRes["DATADOG_HOST_NAME"]);

              window.localStorage.setItem("DISABLED_DRAW", configRes['DISABLED_DRAW']);
              window.localStorage.setItem("DISABLED_PREPAY", configRes['DISABLED_PREPAY']);
              window.localStorage.setItem("DISABLED_PAYMENT_ADJUSTMENT", configRes['DISABLED_PAYMENT_ADJUSTMENT']);
              window.localStorage.setItem("DISABLED_REWARDS", configRes['DISABLED_REWARDS']);
              window.localStorage.setItem("COVERDASH_LICENSE", configRes['COVERDASH_LICENSE']);
              window.localStorage.setItem("COVERDASH_QUOTES_URL", configRes['COVERDASH_QUOTES_URL']);
              window.localStorage.setItem("PAYMENT_CALENDER_INFO_TEXT", configRes['PAYMENT_CALENDER_INFO_TEXT']);
              let configs = { ...configRes };

              if (res['access_token']) {
                window.localStorage.setItem('loginurl', environment.baseUrl)
                window.localStorage.setItem('id_token', res['id_token']);

                //get user details
                this.authService.getUserDetails({email:this.email?.value}).subscribe({
                  next: (details: any) => {
  
                    try {
                      this.BrowserLoggerObj = new BrowserLogger();
                      this.RumLoggerObj = new RumLogger();
                      this.authService.RumLoggerObj$ = this.RumLoggerObj;
                    } catch (error) {
                      //do nothing
                    }
        
                    try {
                      if (this.authService.RumLoggerObj$) {
                        this.authService.RumLoggerObj$.setCustomUser({
                          "displayName": details['name'],
                          "email": details['email'],
                          "appId": details['app_metadata'].merchAppId,
                          "oppId": details['app_metadata'].opportunityId,
                          "cardId": details['app_metadata'].cardId,
                          "name": details['name'],
                          "id": details['app_metadata'].opportunityId,
                          "platform": (window.localStorage.getItem('platform') || 'browser')
                        });
        
        
                        this.authService.RumLoggerObj$.setCustomAction(JSON.stringify({
                          "displayName": details['name'],
                          "email": details['email'],
                          "appId": details['app_metadata'].merchAppId,
                          "oppId": details['app_metadata'].opportunityId,
                          "cardId": details['app_metadata'].cardId,
                          "name": details['name'],
                          "id": details['app_metadata'].opportunityId,
                          "platform": (window.localStorage.getItem('platform') || 'browser')
                        }), {
                          "displayName": details['name'],
                          "email": details['email'],
                          "appId": details['app_metadata'].merchAppId,
                          "oppId": details['app_metadata'].opportunityId,
                          "cardId": details['app_metadata'].cardId,
                          "name": details['name'],
                          "id": details['app_metadata'].opportunityId,
                          "platform": (window.localStorage.getItem('platform') || 'browser')
                        });
        
        
                      }
                    } catch (error) {
                      //do nothing
                    }
                    /*sets user data to rum logs*/
  
                    window.localStorage.setItem('oppId', details['app_metadata'].opportunityId)
                    window.localStorage.setItem('displayName', details['name'])
                    window.localStorage.setItem('login_ts', new Date().getTime().toString());
                    window.localStorage.setItem('email', details['email'])
                    window.localStorage.setItem('name', details['name'])
                    window.localStorage.setItem('cardId', details['app_metadata'].cardId)
                    window.localStorage.setItem('coverdashBusinessId', details['app_metadata'].coverdashBusinessId ?? '')
                    window.localStorage.setItem('contId', details['app_metadata'].contId)
                    window.localStorage.setItem('appId', details['app_metadata'].merchAppId)
                    window.localStorage.setItem('logins_count', details['logins_count'] ? details['logins_count'].toString() : 'NA')
                    window.localStorage.setItem('accountOwner', 'true');
                    window.localStorage.setItem('Grace_Window_Days', configs['Grace_Window_Days']);
                    window.localStorage.setItem('Post_Grace_Premium', configs['Post_Grace_Premium']);
                    window.localStorage.setItem('US_HOLIDAYS', configs['US_HOLIDAYS']);
                    if (details["app_metadata"].isOperator) {
                      window.localStorage.setItem("userMode", "operator");
                    } else {
                      window.localStorage.setItem("userMode", "general");
                    }
                  //  let appName = environment.appName;
                    
                    window.localStorage.setItem("token", JSON.stringify(res));
                    window.localStorage.setItem("appName", environment.appName);
                    if (this.loginForm.value.email.trim() && res['access_token'])
                      this.authService.fetchUserCache(this.loginForm.value.email.trim(), res['access_token']);
                    /*cache implementation*/
                    this.handleNavigationAfterLogin(redirectURL, platformName);
                  },
                  error: (userDetailsError: any) => {
                    this.handleError(userDetailsError);
                  }
                });
              }

              
            },
            error: (configError: any) => {
              this.handleError(configError);
            }
          });
        // },
        // error: (err: any) => {
        //   this.loginError = true;
        //   this.handleError(err);
        // }
      // });
    // } else {
    //   this.loginError = true;
    // }
  }
  sendMFACode (){
    //if loader is visible then return
    if (this.loggedIn) {
      return;
    }

    if (this.loginForm.valid) {
    this.loggedIn = true
    const { email, password } = this.loginForm.value;
    this.loginError = false
    this.authService.loginUserWithMFA(email, password).subscribe({
      next:(res:any)=>{
        if(res.id_token){
          //login directly
          this.login(res);
        }else if(res.mfa_required){
          this.authStorage.saveAuthToken('email', email)
          .then(() => this.authStorage.saveAuthToken('mfa_token', res.mfa_token))
          .then(() => this.authStorage.saveAuthToken('oob_code', res.enrolled_authenticators.oob_code))
          .then(() => {
            this.router.navigate(['/verify-code']);
            this.loggedIn = false;
            
          })

          // this.setSecureCookie('email', email);
          // this.setSecureCookie('mfa_token', res.mfa_token);
          // this.setSecureCookie('oob_code', res.enrolled_authenticators.oob_code);
          // this.router.navigate(['/verify-code']);
          // this.loggedIn = false;
        }
        this.loginError = false;
      },error:(err:any)=>{
        console.log(err)
        this.loggedIn = false;
        this.loginError = true;
      }
    })
  }else{
    console.log('Form is invalid');
  }
  }


  checkIfCookieIsStored(cookieName: string): boolean {
    const cookieValue = this.cookieService.get(cookieName);
    return cookieValue ? true : false; 
  }

  // setSecureCookie(name: string, value: string) {
  //   const days = 7; // Set the cookie for 7 days
  //   const expires = new Date();
  //   expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000)); // Set expiry time
  //   console.log("window.location.hostname : ",window.location.hostname);
  //   this.cookieService.set(name, value, expires, '/', window.location.hostname, true, 'Strict');
  // }

  handleNavigationAfterLogin(redirectURL: string, platformName: string) {
    // setTimeout(() => {
    // }, 5000);
    this.accountService.getAccountSummary().subscribe((res: any) => {
      try {
        res = JSON.parse(res);
        localStorage.setItem('legalName', res.RevAccountSummary.summary.legalName)
        let isCollection = res.RevAccountSummary.summary?.inCollection || 'false';

        if (isCollection === 'true') {
          this.router.navigate(['/dashboard/collection']);
        } else {
          let platformName = window.localStorage.getItem('platform') || 'browser';
          if (redirectURL && platformName !== 'Android' && platformName !== 'iOS') {
            if (redirectURL === '/pricing') {
              this.router.navigate(['/dashboard/prepay-cal']);
            } else {
              this.router.navigate([redirectURL]);
            }
          } else {
            if (localStorage.getItem('skipIntro') && localStorage.getItem('skipIntro') !== 'true' && localStorage.getItem('environment') !== 'web' && localStorage.getItem('environment')) {
              console.log(localStorage.getItem('skipIntro'));
              this.router.navigate(['/walkthrough']);
            } else {
              this.router.navigate(['/dashboard']);
            }
          }
        }
      } catch (error) {
        this.router.navigate(['/dashboard']);
      }
    }, (error: any) => {
      this.router.navigate(['/dashboard']);
    },()=>{
      this.loggedIn = false;
    });
  }



  private handleError(error: any) {
    this.failedLogin = true;
    this.loggedIn = false;
    console.error(error);
  }
  redirectToOtpLogin() {
    if (this.loginForm.value.email.trim()) {
      this.router.navigate(['/otplogin', this.loginForm.value.email.trim()]);
    } else {
      this.router.navigate(['/otplogin']);
    }
  }
  ngOnDestroy(): void {
    this.loggedIn = false;
    this.authService.loggedInSubject.next(false);
  }
}
