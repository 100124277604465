import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-footer-general',
  templateUrl: './footer-general.component.html',
  styleUrls: ['./footer-general.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterGeneralComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() { }
  platform = localStorage.getItem('platform');
  platformAppName = localStorage.getItem('platform_app_name');
  platformAppPackageName = localStorage.getItem('platform_app_package_name');
  platformAppVersionCode = localStorage.getItem('platform_app_version_code');
  platformAppVersionNumber = localStorage.getItem('platform_app_version_number');
  currentYear = new Date().getFullYear();

  callIcon = this.sanitizeSvg(`
    <svg id="Call" class="callIcon" xmlns="http://www.w3.org/2000/svg" width="20.51" height="20.51" viewBox="0 0 20.51 20.51" aria-label="Give Us A Call" role="img">
      <path id="Path_1" data-name="Path 1" d="M0,0H20.51V20.51H0Z" fill="none"/>
      <path id="Path_2" data-name="Path 2" d="M9.1,12.661a12.87,12.87,0,0,0,5.632,5.627L16.61,16.4a.857.857,0,0,1,.867-.209,9.728,9.728,0,0,0,3.051.487.852.852,0,0,1,.855.855v2.991a.852.852,0,0,1-.855.855A14.526,14.526,0,0,1,6,6.855.854.854,0,0,1,6.855,6H9.846a.852.852,0,0,1,.855.855,9.728,9.728,0,0,0,.487,3.051.857.857,0,0,1-.209.867Z" transform="translate(-3.436 -3.436)" fill="#777b92"/>
    </svg>
    `);

  sanitizeSvg(svg: string) {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }
}
