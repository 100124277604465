<main class="main notificationsPage">
  <div class="container">
    <div class="row">
      <div class="col-12 p-0">
        <div class="transactions-card card">
          <div class="card-header">
            <h2 class="title">
              Notifications
            </h2>
          </div>

          <div class="card-body notificationsBody">
            <div class="wrapper">
              <!-- Page content goes here -->
              <div class="tool-bar">
                <div class="tools">
                  <div class="filter-left-container">
                    <form class="" [formGroup]="filtersFormGroup">
                      <div class="left-side-filters">
                        <div class="search-filter">
                          <mat-form-field appearance="outline" class="myMatFormField" subscriptSizing="dynamic">
                            <!-- <mat-label>Enter Email Address</mat-label> -->
                            <input matInput placeholder="Search Notifications" [formControl]="searchBar"
                              name="Search Notifications" aria-label="Please enter your inputs to search notifications">
                            <img matSuffix src="../../../assets/new-dash/search_zoom_icon.svg" class="iconBox"
                              alt="Search icon" />
                          </mat-form-field>

                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="notificationsListWrapper">
                <div class="loaderSectionWrapper" *ngIf="loaderActiveFlag">
                  <div class="loaderListItem mb-4" *ngFor="let item of [1, 2]">
                    <div class="wrapper">
                      <ngx-skeleton-loader [theme]="{
                          width: '80%',
                          'border-radius': '0',
                          height: '15px',
                          'margin-bottom': '10px'
                        }"></ngx-skeleton-loader>
                    </div>
                    <div class="wrapper">
                      <ngx-skeleton-loader [theme]="{
                          width: '90%',
                          'border-radius': '0',
                          height: '15px',
                          'margin-bottom': '10px'
                        }"></ngx-skeleton-loader>
                    </div>
                    <div class="wrapper">
                      <ngx-skeleton-loader [theme]="{
                          width: '60%',
                          'border-radius': '0',
                          height: '15px',
                          'margin-bottom': '10px'
                        }"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="!loaderActiveFlag">
                  <div class="noNotification" *ngIf="filteredNotifications.length === 0; else notificationsListTemplate">
                    <figure class="imgBox">
                      <img src="/assets/new-dash/innerpages/exclamation.svg" alt="Notification Image">
                    </figure>
                    <div class="contentBox">
                      <div class="title">
                        <h2 class="titleContent">
                          No Notifications
                        </h2>
                      </div>
                      <div class="subtitle">
                        <div class="subtitleContent">
                          You have no notifications
                        </div>
                      </div>
                    </div>
                  </div>

                  <ng-template #notificationsListTemplate>
                    <div class="notificationsListItem" *ngFor="let item of filteredNotifications">
                      <div class="imgBoxWrapper">
                        <figure class="imgBox">
                          <img [src]="item.alert ? 
                          '../../../../assets/new-dash/innerpages/exclamation.svg' : 
                          '../../../../assets/new-dash/innerpages/infoIcon_grey.svg'" alt="">
                        </figure>
                      </div>
                      <div class="notificationContentWrapper">
                        <div class="notification-head">
                          <div class="title">
                            <h2 class="titleContent">
                              {{item.title}}
                            </h2>
                            <div class="dotContainer" *ngIf="item.new">
                              <span class="dot"></span>
                            </div>
                          </div>
                          <div class="dateTime">
                            {{item.dateTime}}
                          </div>
                        </div>
                        <div class="contentBox">
                          <div class="subtitle">
                            <div class="subtitleContent description" [innerHTML]="item.subtitleContent">
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                    <mat-paginator (page)="handlePageIndexChange($event)" [length]="notifications.length"
                      [pageIndex]="currentPageIndex" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                      aria-label="Select page" class="paginationControls">
                    </mat-paginator>
                  </ng-template>
                </ng-container>

              </div>


              <!-- Page content goes here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>