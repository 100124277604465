import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-check-mfa-status',
  templateUrl: './check-mfa-status.component.html',
  styleUrls: ['./check-mfa-status.component.scss'],
})
export class CheckMfaStatusComponent implements OnInit {
  errorMessage:any
  constructor(
    public dialogRef: MatDialogRef<CheckMfaStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit() { 
    console.log(this.data ,"error")
    if (this.data?.status === 0) {
      this.errorMessage = "It looks like there’s a network error. Please try again later."
    } else if (this.data?.error?.error === "invalid_grant" || "expired_token") {
      this.errorMessage = "Invalid OTP or OTP Expired"
    } else if (this.data?.error?.error === "invalid_request"){
      this.errorMessage = "Too many failed attempts. Please try again after a few minutes."
    }
    else {
      this.errorMessage = this.data?.error?.errorMessage
    }
    
  }

}
