import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { AccountService } from 'src/app/core/service/account.service';
// Capacitor imports
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Network } from '@capacitor/network';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { isDevMode } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrowserLogger } from './core/browser-logger.service';
import { RumLogger } from './core/rum-logger.service';
import { AuthenticationService } from './core/service/authentication.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit {
  // title = 'revcarddev';
  status = 'ONLINE';
  isConnected = true;

  mobileTabFlag: boolean = false;

  BrowserLoggerObj: any = null;
  RumLoggerObj: any = null;

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private platform: Platform,
    public alertController: AlertController,
    private authService: AuthenticationService,
    public accountService: AccountService,
    private renderer: Renderer2
  ) {
    this.initializeApp();

    // Detect Safari browser and add class to body
    this.detectSafariBrowser();
  }

  detectSafariBrowser() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    console.log('Is Safari:', isSafari);
    if (isSafari) {
      this.renderer.addClass(document.body, 'safari-browser');
    }
  }


  async showSplashScreen() {
    console.log('Showing splash screen');
    await SplashScreen.show({
      showDuration: 12000,
      autoHide: true
    });
  }

  async getAppDetails() {
    try {
      // Get the app name, version, and build number using App and Device plugins
      const appInfo = await App.getInfo(); // Returns app name and package id
      const deviceInfo = await Device.getInfo(); // Returns device info and app version
      console.log(appInfo);
      console.log(deviceInfo);
      const appName = appInfo.name;
      const packageName = appInfo.id;
      const appVersion = appInfo.version;
      const appBuild = appInfo.build;

      // Store in localStorage
      localStorage.setItem('platform_app_name', appName);
      localStorage.setItem('platform_app_package_name', packageName);
      localStorage.setItem('platform_app_version_code', appBuild); // appBuild as version code
      localStorage.setItem('platform_app_version_number', appVersion);

      // Optional: Check app supported versions
      this.checkAppSupportedVersions(localStorage.getItem('platform'), appVersion);
    } catch (err) {
      console.error('Error fetching app details:', err);
    }
  }
  //   checkAppSupportedVersions(platform: string | null, version: string | null) {
  //   console.log('Platform:', platform);
  //   console.log('App Version:', version);

  // }


  public checkAppSupportedVersions(deviceType: any, deviceVersion: any) {
    console.log('Checking app supported versions');
    console.log('Device Type:', deviceType);
    console.log('Device Version:', deviceVersion);
    this.accountService.globalDataLoader = true;
    this.authService.checkAppSupportedVersions(deviceType).subscribe((res: any) => {
      console.log('App supported versions response');
      console.log(res?.status);
      this.accountService.globalDataLoader = false;
      if (res?.status !== 'success') {
        console.log(res?.versions);
        if (res?.disabled_status === true || res?.disabled_status === 'true') {
          this.router.navigate(['/disabled']);
          return;
        }
        if (res?.versions) {
          console.log(res?.versions.length);
          if (res?.versions.length) {
            let supportedVersions = res?.versions;
            if (!supportedVersions.includes(deviceVersion)) {
              this.router.navigate(['/upgrade']);
              return;
            }
          }
        }
      }
    },
      (err: any) => {
        console.log('Error checking app supported versions');
        this.accountService.globalDataLoader = false;
        console.log(err);

      }
    );


  }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const oppId = urlParams.get('oppId');

    if (window.location.hostname.startsWith('offers') && window.location.hostname.endsWith('.revenued.com')) {
      this.router.navigate(['/pricing'], { queryParams: { oppId } });
    }

    console.log("isDevMode ", isDevMode())
    if (!isDevMode()) {
      console.log = () => { };
      console.error = () => { };
      console.warn = () => { };
      console.info = () => { };
      console.debug = () => { };
      console.trace = () => { };
    }

    try {
      this.BrowserLoggerObj = new BrowserLogger();
      this.RumLoggerObj = new RumLogger();
      this.authService.RumLoggerObj$ = this.RumLoggerObj;


    } catch (error) {
      //do nothing
    }


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hideSplashScreen();
        // const route = this.activatedRoute.firstChild;
        const route = this.getDeepestChild(this.activatedRoute);
        if (route) {
          const pageTitle = route.snapshot.data['title'] || 'Revenued';
          this.titleService.setTitle(pageTitle);
        }
      }



    });

    // Hide console logs in production mode
    // if (!Capacitor.isNativePlatform()) {
    //   console.log = () => { };
    //   console.error = () => { };
    //   console.warn = () => { };
    // }
  }

  // Initialize app and setup platform-specific configurations
  async initializeApp() {
    console.log('Initializing app');
    this.platform.ready().then(async () => {
      const info = await Device.getInfo();
      await this.checkInternetConnection();
      console.log('Platform ready');
      console.log(Capacitor.getPlatform());
      if (Capacitor.isNativePlatform()) {
        console.log('Native platform detected');

        localStorage.setItem('environment', 'device');



        if (info.platform === 'android') {
          console.log('android');
          localStorage.setItem('platform', 'Android');
        } else if (info.platform === 'ios') {
          console.log('ios');
          // this.showSplashScreen();
          document.body.classList.add('ios-fix');
          console.log('ios');
          console.log(info.platform);
          console.log("adding ios-fix");
          document.body.style.paddingTop = `${20}px`;
          localStorage.setItem('platform', 'iOS');
        } else {
          localStorage.setItem('platform', 'Web');
        }
        //temp


        this.getAppDetails();
        //temp


        // Subscribe to resume event when app comes to foreground
        this.platform.resume.subscribe(() => {
          this.onAppResume();
        });

        await this.setupStatusBar();

        this.setupBackButton();

        if (localStorage.getItem('environment') !== 'web') {
          this.checkForMaintenanceMode();
        }
      } else {
        localStorage.setItem('environment', 'web');
        localStorage.setItem('platform', 'Web');
      }
    });
  }
  onAppResume() {
    console.log('App resumed');
    // Check if the environment is not 'web'
    console.log(localStorage.getItem('environment'));
    if (localStorage.getItem('environment') !== 'web') {
      this.getAppDetails();
      this.checkForMaintenanceMode();

    }
    console.log(window.localStorage.getItem("token"));
    // Check if token exists in localStorage and validate it
    if (window.localStorage.getItem("token")) {
      this.authService.isTokenIdValid().subscribe(
        (res) => {
          // If token is valid, you can handle navigation or any other actions
          // Example: this.router.navigate(['/dashboard']);
        },
        () => {
          console.log('Token is invalid');


          // If the token is invalid, clear it and navigate to the login page
          this.router.navigate(['/login']);
          window.localStorage.removeItem("token");
          window.location.reload();
        }
      );
    }
  }

  public checkForMaintenanceMode() {
    console.log('Checking for maintenance mode');
    // Call your API to check for maintenance mode
    this.accountService.globalDataLoader = true; // Show loading spinner

    this.authService.checkForMaintainanceMode().subscribe(
      (res: any) => {
        this.accountService.globalDataLoader = false;
        console.log(res);
        if (res?.success) {
          console.log('Maintenance mode is active');
          // Redirect to the maintenance page
          this.redirectToMaintainancePage();
        } else {
          console.log('Maintenance mode is inactive');
          // Handle non-maintenance case (navigate to dashboard or home)
          // this.router.navigate(['/']);
        }
      },
      (err: any) => {
        console.log('Error checking for maintenance mode');
        this.accountService.globalDataLoader = false;
        console.log(err);
        //if app is not able to contact internal server/partner then redirect to maintainance page
        this.redirectToMaintainancePage();
        // Optionally handle errors (show message, etc.)
      }
    );
  }

  public redirectToMaintainancePage() {
    // Check if the app is online before navigating
    if (this.isConnected) {
      this.router.navigate(['/maintainance']);
    }
  }
  // Set up the status bar appearance
  async setupStatusBar() {
    try {
      await StatusBar.show();
      console.log('Setting up status bar');
      console.log(Capacitor.getPlatform());

      if (Capacitor.getPlatform() === 'ios') {
        console.log("Running on iOS");

        // iOS: Set style instead of background color
        await StatusBar.setStyle({ style: Style.Dark });
        await StatusBar.setBackgroundColor({ color: "#0127A5" });
        // iOS: Try to enable overlay (default behavior)
        await StatusBar.setOverlaysWebView({ overlay: true });

      } else {
        console.log("Running on Android");

        // Android: Set background color and style
        // await StatusBar.setStyle({ style: Style.Dark });
        await StatusBar.setBackgroundColor({ color: "#0127A5" });

        await StatusBar.setOverlaysWebView({ overlay: false });//not needed for android
      }

    } catch (err) {
      console.error('StatusBar error:', err);
    }
  }

  // Check internet connection using Capacitor Network plugin
  public async checkInternetConnection() {
    console.log('Checking internet connection');
    // Monitor for real-time network status changes
    Network.addListener('networkStatusChange', (status) => {
      this.isConnected = status.connected;
      this.status = this.isConnected ? 'ONLINE' : 'OFFLINE';
      console.log(this.status);
      if (this.isConnected) {
        console.log('Online');
        // this.router.navigate(['/dashboard']);

      } else {
        console.log('Offline');
        this.router.navigate(['/no-internet']);
      }
    });

    // Check the initial status once when the app starts
    const status = await Network.getStatus();
    this.isConnected = status.connected;
    this.status = this.isConnected ? 'ONLINE' : 'OFFLINE';

    if (!this.isConnected) {
      console.log('Offline');
      this.router.navigate(['/no-internet']);
    }
  }

  // Set up the back button handling
  setupBackButton() {
    App.addListener('backButton', ({ canGoBack }) => {
      if (canGoBack) {
        window.history.back();
      } else {
        this.showExitConfirm();
      }
    });
  }

  // Confirm app exit
  async showExitConfirm() {
    const alert = await this.alertController.create({
      header: '',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Stay',
          role: 'cancel',
          handler: () => {
            console.log('Application exit prevented!');
          },
        },
        {
          text: 'Exit',
          handler: () => {
            App.exitApp();
          },
        },
      ],
    });
    await alert.present();
  }

  // Scroll-to-top functionality
  isShow: boolean = false;
  topPosToStartShowing = 100;
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isShow = scrollPosition >= this.topPosToStartShowing;
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


  private getDeepestChild(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  hideSplashScreen() {
    setTimeout(() => { // Delay to ensure DOM is fully loaded
      const splashScreen = document.getElementById('splash-screen');
      // console.log(splashScreen);
      if (splashScreen) {
        splashScreen.style.opacity = '0';
        setTimeout(() => splashScreen.remove(), 500); // Smooth fade-out
      } else {
        console.warn("Splash screen not found!");
      }
    }, 0);
  }

  ngAfterViewInit() {
    if (window.innerWidth < 768) {
      this.mobileTabFlag = true;
    }
  }
}



