import { Injectable } from '@angular/core';
import { datadogLogs as datadog } from '@datadog/browser-logs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrowserLogger {
  private initialized = false;
  constructor() {
    if (!environment?.datadog) {
      return;
    }

    datadog.init({
      clientToken: 'pub8cb43d5da1993ea080626d47b04b43a5',
      site: 'datadoghq.com',
      service: `customer_portal_${environment.appEnvName}`,
      forwardErrorsToLogs: true,
      sampleRate: parseInt(window.localStorage.getItem('DATADOG_OPTION_SAMPLE_RATE')||"") || 100,
      trackSessionAcrossSubdomains: true,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true,      
      allowFallbackToLocalStorage: true,
      silentMultipleInit: true
    });
    this.initialized = true;
    //log browser collection init
    this.info("User session loaded!", { name: 'BrowserLoggerInit', status: 'load' });

  }

  public debug(message: string, context?: { [x: string]: any }): void {
    if (this.initialized) {
      datadog.logger.debug(message, context);
    }
  }

  public info(message: string, context?: { [x: string]: any }): void {
    if (this.initialized) {
      datadog.logger.info(message, context);
    }
  }

  public warn(message: string, context?: { [x: string]: any }): void {
    if (this.initialized) {
      datadog.logger.warn(message, context);
    }
  }

  public error(message: string, context?: { [x: string]: any }): void {
    if (this.initialized) {
      datadog.logger.error(message, context);
    }
  }
}