export enum TransactionStatus {
    ctsCleared = "CLEARED",
    ctsDeclined = "DECLINED",
    ctsReversed = "REVERSED",
    ctsPending = "PENDING",
    ctsApproved = "APPROVED",
    ctsRefund = "REFUND",
    ctsDispute = "DISPUTE",
    ctsExpired = "EXPIRED"
}
 export declare type TransactionType = keyof typeof TransactionStatus;