import { Component, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
// import { NgxScrollEvent } from 'ngx-scroll-event';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TransactionStatus } from '../../core/model/transaction.status';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import * as moment from 'moment';
import { CurrencyPipe } from '@angular/common';
import { AccountService } from 'src/app/core/service/account.service';

@Component({
  selector: 'app-transactionhistory-iframe',
  templateUrl: './transactionhistory.component.html',
  styleUrls: ['./transactionhistory.component.scss'],
  providers:[CurrencyPipe]
})
export class TransactionhistoryIframeComponent implements OnInit {
  searchHistory(searchHistory: any) {
    throw new Error('Method not implemented.');
  }
  currentTransactions: any[] = [];
  spendingAvailability!: number;
  @ViewChild('invalidDateTemplate', { static: true })
  invalidDateTemplate!: TemplateRef<any>;
  constructor(public accountService: AccountService, private activatedRoute: ActivatedRoute, public router: Router , public modalService: BsModalService) {

  }
  //latest available transaction date to latest avaible
  modalRef!: BsModalRef;
  isTransactionsLoaded: boolean = false;
  fetchingTransactions: boolean = false;
  transactionHistory: any;
  transactionHistoryLength = 0;
  filteredTransactions: Array<any> = [];
  filteredTransactionsLength = 0;
  transactionStatus = TransactionStatus;
  myMath = Math;
  itemsPerPage = 20;
  popoverInfo: any;
  bsValue = new Date();
  bsRangeValue!: Date[];
  maxDate = new Date();
  minDate!: Date;
  currentPage: any;
  selectedDateRange: any;
  searchKey!: string;
  startDate!: Date;
  endDate!: Date;
  pageSize = 10;
  pageIndex = 1;
  transactionsPlaceholderMessage = null;
  debounceTimer: any;
  totalRowCount = 0;
  init: boolean = true;
  token = '';
  oppId = '';
  appname = '';
  sortBy = '-trxn.recDate'
  whereFilter = ''
  filterStatus = [
    { text: 'CLEARED', value: 'cleared' },
    { text: 'FAILED', value: 'failed' }
  ];
  expandSet = new Set<number>();

  uuidv4() { // Public Domain/MIT
    return `${Math.random().toString(36).substr(2, 9)}_${Math.random().toString(36).substr(2, 9)}`;
  }


  // trxn
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  roundOffNumber(num:any) {
    return Math.round((parseFloat(num) + Number.EPSILON) * 10000) / 10000;
    // return Math.floor(num * 100 + Number.EPSILON) / 100;
  }



  isMobileView() {
    const transHisElement = document.getElementById("trans_his");
   return (transHisElement && transHisElement.getBoundingClientRect().width < 480) || (/iphone|ipod|android|ie|blackberry|fennec/).test(navigator.userAgent.toLowerCase());
      }
  tabName = "transactions";
  activeId : any;
  ngOnInit() {

    this.activatedRoute.params.subscribe((res) => {
      this.oppId = this.activatedRoute.snapshot.paramMap.get('oppId')||'';
      this.token = this.activatedRoute.snapshot.paramMap.get('token')||'';
      this.appname = this.activatedRoute.snapshot.paramMap.get('appname')||'';
      if (this.router.url.split('/')[5]) {
        this.tabName = this.router.url.split('/')[5];
      }

      if (this.router.url.split('/')[6]) {
        this.activeId = this.router.url.split('/')[6];
      }
    });


  }
  onDateChange(dateRange?: any, dateType?: any) {
    if (dateType === 'minDate' && this.selectedDateRange) { this.selectedDateRange[0] = dateRange }
    if (dateType === 'maxDate' && this.selectedDateRange) { this.selectedDateRange[1] = dateRange }
    if (this.selectedDateRange && (this.selectedDateRange[0].getTime() > this.selectedDateRange[1].getTime())) {
      this.modalRef = this.modalService.show(this.invalidDateTemplate);
    }
    else {
      if (this.transactionHistory) {
        this.filteredTransactions = this.transactionHistory.filter((t: { trxn: { recDate: string | number | Date; }; }, i: any) => {
          var tranTime = new Date(t.trxn.recDate).getTime();
          return tranTime >= this.selectedDateRange[0].getTime() && tranTime <= this.selectedDateRange[1].getTime();
        })
        this.currentTransactions = this.filteredTransactions.sort((a, b) => Number(moment(b.trxn.recDate).format('YYYYMMDD')) - Number(moment(a.trxn.recDate).format('YYYYMMDD')))
      }
    }
  }



  switchTab(tabName:any) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      // tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    const activeTab = document.getElementById(tabName);
    if (activeTab) {
    activeTab.style.display = "block"; 
  }
    this.tabName = tabName;
  }

  redirectTab(tabName: any) {
    if (tabName === 'transactions') {
      this.router.navigate(['/getTransactionHistory/' + this.oppId + '/' + this.appname + '/' + this.token + '/transactions']);
    } else {
      this.router.navigate(['/getTransactionHistory/' + this.oppId + '/' + this.appname + '/' + this.token + '/payments']);
    }
  }

}
