import { Component, ElementRef, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CheckMfaStatusComponent } from './check-mfa-status/check-mfa-status.component';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { AuthStorageService } from '../auth/auth-storage.service';

@Component({
  selector: 'app-verify-login-otp',
  templateUrl: './verify-login-otp.component.html',
  styleUrls: ['./verify-login-otp.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerifyLoginOTPComponent implements OnInit {
  otpForm: FormGroup;
  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef>;

  errorMessage = '';
  timer: number = 30; // Resend OTP cooldown (in seconds)
  isResendDisabled: boolean = true;
  staticOtp: string = "123456";
  verified = false;
  email:string | null = null;
  mfaToken: string | null = null;
  oobCode: string | null = null; 
  isOtpDisabled = false; 
control: any;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public authService: AuthenticationService,
    private cookieService: CookieService,
    private router: Router,
    private toastr: ToastrService,
    private authStorage: AuthStorageService
  ) {
    // const mfaDetails = this.authService.getMfaDetails();
    // this.mfaToken = mfaDetails.mfaToken;
    // this.oobCode = mfaDetails.oobCode;
    // this.email = mfaDetails.email;
    // this.mfaToken = localStorage.getItem('mfa_token');
    // this.oobCode = localStorage.getItem('oob_code');
    // this.email =  localStorage.getItem('email');
    //  this.email = this.cookieService.get('email');
    //  this.mfaToken = this.cookieService.get('mfa_token');
    //  this.oobCode = this.cookieService.get('oob_code')
  
    // if (!this.mfaToken || !this.oobCode || !this.email) {
    //   this.router.navigate(['/login']);
    // }
  
    this.loadTokens();
    // if (!this.authService.getMfaDetails().mfaToken || !this.authService.getMfaDetails()) {
    //   this.router.navigate(['/login']); 
    // }

    // console.log('MFA Token:', this.mfaToken);
    // console.log('OOB Code:', this.oobCode);

    this.otpForm = this.fb.group({
      otp1: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
      otp2: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
      otp3: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
      otp4: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
      otp5: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
      otp6: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
    });

    this.startTimer(); // Start countdown on component load
  }


  async loadTokens() {
    try {
      this.email = await this.authStorage.getAuthToken('email');
      this.mfaToken = await this.authStorage.getAuthToken('mfa_token');
      this.oobCode = await this.authStorage.getAuthToken('oob_code');
      
      console.log('Email:', this.email);
      console.log('MFA Token:', this.mfaToken);
      console.log('OOB Code:', this.oobCode);

      if (!this.mfaToken || !this.oobCode || !this.email) {
        this.router.navigate(['/login']);
      }
      
    } catch (err) {
      console.error('Error retrieving tokens:', err);
    }
  }

  ngOnInit(): void {
   
  }
  get isAnyControlInvalid(): boolean {
    return Object.keys(this.otpForm.controls).some(field => {
      const control = this.otpForm.get(field);
      // Only consider a control "invalid" if it's invalid and either touched or dirty
      return control && control.hasError('pattern') && (control.dirty || control.touched);
    });
  }


  /**
  * Handles key input for auto-navigation
  */
  onKeyUp(event: KeyboardEvent, index: number) {
    const inputFields = this.otpInputs.toArray();
    const target = event.target as HTMLInputElement;

    if (event.key >= '0' && event.key <= '9') {
      if (index < inputFields.length - 1) {
        inputFields[index + 1].nativeElement.focus(); // Move to the next input
      }
    } else if (event.key === 'Backspace' && target.value === '') {
      if (index > 0) {
        inputFields[index - 1].nativeElement.focus(); // Move to the previous input
      }
    }
  }


  /**
   * Handles OTP pasting
   */
  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
    const pastedData = event.clipboardData?.getData('text') || '';
    const otpDigits = pastedData.replace(/\D/g, '').split('').slice(0, 6); // Extract numbers only

    if (otpDigits.length !== 6) {
      this.errorMessage = 'Invalid OTP. Please enter all 6 digits.';
      return;
    }

    // Fill OTP fields with pasted data
    this.otpInputs.toArray().forEach((input, index) => {
      input.nativeElement.value = otpDigits[index] || '';
      this.otpForm.controls[`otp${index + 1}`].setValue(otpDigits[index] || '');
    });

    // Move focus to the last input
    this.otpInputs.last.nativeElement.focus();
    this.errorMessage = ''; // Clear error message on successful paste
  }

  /**
   * Submits the OTP
   */
  submitOtp() {
    if (this.otpForm.valid) {
      this.verified = true;
      const otpValue = Object.values(this.otpForm.value).join('');
      // console.log('Entered OTP:', otpValue);
      if(!this.mfaToken || !this.oobCode){
        this.router.navigate(['/login']); 
      }

      // const mfaDetails = this.authService.getMfaDetails();
      // this.mfaToken = mfaDetails.mfaToken;
      // this.oobCode = mfaDetails.oobCode;
      // this.email = mfaDetails.email;

      this.authService.verifyOtp(this.email!, this.mfaToken!, this.oobCode!, otpValue).subscribe({
        next:(res:any)=>{
          // this.toastr.success('OTP Verified Successfully');
          console.log(res,'OTP Verified Successfully');
          //add code for login.
          this.authService.afterMFALogin(res)
        },error:(err:any)=>{
          this.verified = false;
          this.otpForm.reset();
          this.dialog.open(CheckMfaStatusComponent, {
            data: err,
            panelClass: 'checkMFAStatusDialogWrapper',
          });
        }
      })

      this.errorMessage = ''; // Clear any previous errors
    } else {
      this.errorMessage = 'Please enter a valid 6-digit OTP.';
    }

    // const enteredOtp = Object.values(this.otpForm.value).join('');
    // if (enteredOtp !== this.staticOtp) {
    //   this.dialog.open(CheckMfaStatusComponent, {
    //     data: { message: "Invalid OTP or OTP Expired" },
    //     panelClass: 'checkMFAStatusDialogWrapper',
    //   });
    // } else {
    //   console.log('OTP Verified Successfully'); // Handle successful verification
    // }
  }


  /**
   * Starts the OTP resend timer
   */
  startTimer() {
    this.isResendDisabled = true;
    this.timer = 30;

    const interval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isResendDisabled = false;
        clearInterval(interval);
      }
    }, 1000);
  }

  /**
   * Handles Resend OTP action
   */
  resendOtp() {
    this.startTimer();
    this.otpForm.reset();
    this.authService.resend2FAChallenge(this.mfaToken!).subscribe({
      next:(res:any)=>{
        this.setSecureCookie('oob_code', res.enrolled_authenticators.oob_code);

     console.log(res)
      },error:(err:any)=>{
     console.log(err)
      }})
    console.log('New OTP sent'); // Replace with actual API call
  }

  setSecureCookie(name: string, value: string) {
    const days = 7; // Set the cookie for 7 days
    const expires = new Date();
    expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000)); // Set expiry time

    this.cookieService.set(name, value, expires, '/', window.location.hostname, true, 'Strict');
  }

}
