<section id="trans_his" style="padding-bottom: 14px;">
    <div class="container" *ngIf="!refreshRequired">
        <div class="card1  d-none d-md-block d-lg-block d-xl-block">
            <div class="navbar navbar-expand-lg mt-3 mob-date">
                <h3 class="navbar-brand mr-auto font-weight-bold text-uppercase">
                    Payments
                </h3>
            </div>
            <div class="paymentsTable table-responsive d-none d-md-block d-lg-block d-xl-block tabel-padding">

                <ng-container *ngIf="this.currentTransactions.length">
                    <div class="form-group m-3 tabel-padding">
                        <div class="icon-addon addon-lg">
                            <label for="search" class="fa fa-search" rel="tooltip" title="Search..."
                                *ngIf="!searchItem"></label>
                            <input type="text" id="search" placeholder="Search..." class="form-control search-box"
                                [(ngModel)]="searchItem">
                            <label class="fa fa-close mouse-pointerz" (click)="searchItem=null"
                                *ngIf="searchItem"></label>
                        </div>

                    </div>
                </ng-container>


                <nz-table nzShowSizeChanger [nzData]="currentTransactions" [nzFrontPagination]="false"
                    [nzShowSizeChanger]="false" [nzLoading]="!isTransactionsLoaded" [nzTotal]="totalRowCount"
                    [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" (nzQueryParams)="onQueryParamsChange($event)"
                    class="outerTable">
                    <thead class="outerTableThead">
                        <tr class="outerTableRow">
                            <th nzWidth="60px"></th>
                            <th nzColumnKey="sysDate" [nzSortOrder]="'descend'" [nzSortFn]="true"
                                [nzSortPriority]="true">Date</th>
                            <th nzColumnKey="name" [nzFilterFn]="true" [nzSortPriority]="true">Payment ID</th>
                            <th nzColumnKey="name" [nzFilterFn]="true" [nzSortPriority]="true">Description</th>
                            <th nzColumnKey="status" [nzFilterFn]="true" [nzSortPriority]="true">Status</th>
                            <!-- <th nzColumnKey="status" [nzSortFn]="true" [nzSortPriority]="true" [nzFilterFn]="true">Sheet
                            </th> -->
                            <th nzColumnKey="amount" [nzSortFn]="true" [nzSortPriority]="true">Amount</th>
                        </tr>

                    </thead>
                    <tbody>
                        <ng-container *ngFor="let transaction of currentTransactions">
                            <tr [ngClass]="expandSet.has(transaction.id) ? 'active': ''"
                                class="{{!this.accountService.checkIsVisible(transaction)?'d-none':''}}">
                                <td class="expander" [nzExpand]="expandSet.has(transaction.id)"
                                    (nzExpandChange)="onExpandChange(transaction.id, null)"
                                    (click)="expandInnerTable(transaction.id, $event)">
                                </td>
                                <td>{{transaction.sysDate | date}}</td>
                                <!-- (click)="showPaymentModal(transaction.id,transaction)" -->
                                <td><a (click)="openPaymentModal(transaction.id,transaction)"
                                        class="mouse-pointerz">{{transaction.id}}</a></td>

                                <td>{{transaction.notes}}</td>
                                <td>{{this.accountService.getTitleForHistory(transaction)}}</td>
                                <!-- <td>{{transaction.achSheet}}</td> -->
                                <td class="">{{this.accountService.getFormattedAmount(transaction.amount)}}</td>
                            </tr>
                            <tr [nzExpand]="expandSet.has(transaction.id)" class="expandedRow">
                                <nz-table #innerTable [nzData]="transaction" nzSize="middle" [nzShowPagination]="false"
                                    class="innerTable">
                                    <thead>
                                        <tr class="innerTableRow">
                                            <th scope="col">Date</th>
                                            <th scope="col">Transaction ID</th>
                                            <!-- <th scope="col" style="width: 200px;">Description</th> -->
                                            <th scope="col">Status</th>
                                            <th scope="col" class="text-right">Transaction Amount</th>
                                            <th scope="col" class="text-right">Starting Bal to Done</th>
                                            <th scope="col" class="text-right">Payment Allocation</th>
                                            <th scope="col" class="text-right">Discount Savings</th>
                                            <th scope="col" class="text-right">Ending Bal to Done</th>
                                        </tr>
                                    </thead>
                    <tbody *ngIf="this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail">
                        <tr *ngFor="let item of this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail"
                            class="innerTableRow {{!this.accountService.checkIsVisible(item)?'d-none':''}}">
                            <td>{{item.sysDate | date}}</td>
                            <td class="paymentLink"><a class="mouse-pointerz"
                                    (click)="openTransactionModal(item.tid,item)">{{item['tid']}}</a>
                            </td>
                            <td>{{this.accountService.getTitleForHistory(item)}}</td>
                            <td class="text-right">{{ this.accountService.getFormattedAmount(item.fundedAmount)}}</td>
                            <td class="text-right">{{this.accountService.getFormattedAmount(item.paybackAmount)}}</td>
                            <td class="text-right">{{ this.accountService.getFormattedAmount(item.amount) }}</td>
                            <td class="text-right">{{ this.accountService.getFormattedAmount(item.totSaveAmt) }}</td>
                            <td class="text-right">



                                {{

                                this.accountService.getFormattedAmount(item.balToDone)
                                }}


                            </td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="7">No more transaction(s) to display</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail" class="text-center">
                        <tr>
                            <td colspan="7">
                                <span *ngIf="isTransactionDetailsLoaded">
                                    No Transaction(s) to display
                                </span>
                                <span *ngIf="!isTransactionDetailsLoaded">
                                    <div class="loader xs-loader mx-auto"></div> Retrieving Transaction(s)...
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
                </tr>


                </ng-container>
                <tr class="text-center" *ngIf="isTransactionsLoaded">
                    <td colspan="6">No more payment(s) to display</td>
                </tr>
                </tbody>
                </nz-table>


            </div>
        </div>
        <div class="mobile_view paymentsTable-mob d-xl-none d-sm d-md-none d-lg-none">

            <ng-container *ngIf="this.currentTransactions.length">
                <div class="form-group m-3 tabel-padding">
                    <div class="icon-addon addon-lg">
                        <label for="search" class="fa fa-search" rel="tooltip" title="Search..."
                            *ngIf="!searchItem"></label>
                        <input type="text" id="search" placeholder="Search..." class="form-control search-box"
                            [(ngModel)]="searchItem">
                        <label class="fa fa-close mouse-pointerz" (click)="searchItem=null" *ngIf="searchItem"></label>
                    </div>

                </div>
            </ng-container>

            <div class="card shadow mb-3 {{!this.accountService.checkIsVisible(transaction)?'d-none':''}}"
                *ngFor="let transaction of currentTransactions ">
                <table class="table">
                    <tr>
                        <td class="border-0" style="max-width: 200px;">
                            <p class="font-weight-bold">{{transaction.notes}}</p>
                        </td>
                        <td class="border-0">{{
                            this.accountService.getFormattedAmount(transaction.amount)
                            }}</td>
                    </tr>
                    <tr>
                        <td class="border-0" colspan="2" style="padding: 0 !important;">
                            <nz-collapse [nzBordered]="false" [nzGhost]="true" [nzExpandIconPosition]="'right'">
                                <nz-collapse-panel class="border-0 mutedText indicator-bar"
                                    [ngClass]="indicator? 'success' : 'fail'"
                                    [nzHeader]='(transaction.sysDate | date)  +" &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; "  +  this.accountService.getTitleForHistory(transaction)'
                                    [nzActive]="transaction.active" [nzDisabled]="transaction.disabled">
                                    <p style="margin:0;">
                                        <button nz-button nzType="primary" class="primaryPaymentBtn"
                                            (click)="openPaymentModal(transaction.id,transaction)">
                                            View Allocation
                                        </button>
                                    </p>
                                </nz-collapse-panel>


                            </nz-collapse>

                        </td>
                    </tr>
                </table>
            </div>

            <div *ngIf="isTransactionsLoaded">
                <div class="d-flex justify-content-center p-5 border-0">
                    <b>{{'No more payment(s) to display'}} </b>
                </div>
            </div>

        </div>
        <div *ngIf="!isTransactionsLoaded || (currentTransactions && currentTransactions.length===0)">
            <div class="d-flex justify-content-center p-5 border-0">
                <b>{{'Retrieving payments...'}} </b>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="refreshRequired">
        <h3>Session Expired</h3>
        <h5> Please refresh to continue.</h5>
    </div>
</section>