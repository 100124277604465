// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  buildname: 'its in production',
  baseUrl: 'https://api-uat.revenued.com/',
  // baseUrl: 'https://revenued-middleware-uat.herokuapp.com/',
  appUrl: 'https://revcard-new-ui-f3cdb94e822c.herokuapp.com/',
  // appUrl: 'https://app-dev.revenued.com/',
  playstoreAppUrl: "https://play.google.com/store/apps/details?id=com.revenuedapp.www",
  appstoreAppUrl: "https://apps.apple.com/us/app/apple-store/id375380948",
  datadog: true,
  soapUrl: '/',
  appEnvName: 'uat',
  appName: 'RevenuedUAT',
  REVENUED_MIDDLEWARE_TOKEN: '99d011e6-75c7-47f0-ab61-81d884c8099d'
};
export const httpInterceptorEnvironment = {
  errorHandlerRetryAttemt: 0,//by default retry once in future, fetch retry attempt # from request headers of api calls
  showInterceptorLoader: false,//keep it false for production
  bypassGlobalErrorHandler: true //i will have to remove this becuase this will disturb existing system  or implement after completing interceptor tasks
}
/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
