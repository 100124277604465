<div class=" errorPage" style="height: 100%; background-color: #fff">
  <div class="container">
    <div class="row h-100">
      <div class="col-12">
        <div class="content-area">
          <div class="logoBox">
            <img src="../../../assets/new-dash/logo/revenuedLogo_black.svg" alt="Revenued">
          </div>
          <div class="title">
            <h2>
              Service is temporarily down for <br />
              maintainance
            </h2>
          </div>
          <div class="server-down-img">
            <img class="bg-image" src="../../../assets/new-dash/error-handling-bg.webp" alt="Error" />
          </div>
          <div class="other-info">
            <button type="button" class="primary-general-btn btn-center mb-3" (click)="clearData()" mat-button
              cdkFocusInitial>Login</button>
            <p>We apologize for any inconveniences caused</p>
            <p>
              Please try again later, or call customer service at
              <a href="tel:+1-877-662-3489" target="_blank" class="linkStyles">
                +1-877-662-3489
              </a>
              for
              urgent enquiries
            </p>
            <h2 class="oopsError">Oops! - {{code}} Error</h2>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>