import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/core/service/account.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-price-confirmation',
  templateUrl: './price-confirmation.component.html',
  styleUrls: ['./price-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PriceConfirmationComponent implements OnInit {
  @Input() offerData: any;
  @Input() selectedOption: string | undefined;
  @Input() rawOfferObj: any;
  @Input() oppId: any;
  @Input() optionIndex: number | undefined;

  offerMaxLimit: number | undefined;
  loading = false;
  offerTerm: number | undefined;
  offerFactor: number | undefined;
  offerDailyPayment: any;
  collectionSchedule: string | undefined;
  termsChangedError = false;
  termsChangedErrorMsg = '';

  constructor(
    public modalRef1: BsModalRef,
    public modalRef2: BsModalRef,
    private modalService: BsModalService,
    private accountService: AccountService,
    private toastr: ToastrService
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.offerMaxLimit = this.offerData.limit;
    this.offerTerm = this.offerData.term;
    this.offerFactor = this.offerData.factor;
    this.offerDailyPayment = this.offerData.dailyPayment;
    this.collectionSchedule = this.offerData.collectionSchedule;
  }

  reloadPage() {
    window.location.reload();
  }

  @Output() loadingChange = new EventEmitter<boolean>();
  openStatusModal(template: TemplateRef<any>) {
    const offerId = this.offerData.offerId || '';
    this.loading = true;
    this.loadingChange.emit(this.loading);
    this.termsChangedError = false;
    this.termsChangedErrorMsg = '';
    // if (this.loading && this.modalRef1) {
    //   this.modalService.config.ignoreBackdropClick = true;
    //   // console.log("i am here Loading: " + this.loading);
    // }
    this.accountService.acceptOffer(this.oppId, offerId, this.rawOfferObj).subscribe(
      (response) => {
        this.modalRef1.hide();
        this.toastr.success('Offer accepted successfully!');
        this.modalRef2 = this.modalService.show(template, { class: 'modal-lg statusModal', ignoreBackdropClick: false });
        this.loading = false;
        this.loadingChange.emit(this.loading);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      },
      (error) => {
        this.loading = false;
        this.loadingChange.emit(this.loading);
        this.termsChangedError = error.error.includes('terms have changed');
        const isGenericError = error.error.includes('GENERIC_ERROR');
        // this.modalService.config.ignoreBackdropClick = false;
        if (!this.termsChangedError) {
          this.toastr.error(`${isGenericError ? 'Unexpected error occurred, try again later' : error.error}. Please contact Revenued Support if the issue persists.`);
        } else {
          this.termsChangedErrorMsg = `${error.error}.`;
        }
        console.error('Error accepting offer:', error);
      }
    );
  }
}
