import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
// Other imports
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
// import { ConformationModalComponent } from './conformation-modal/conformation-modal.component';

@NgModule({
  declarations: [
    // ConformationModalComponent
  ],
  imports: [
    CommonModule,
    NzPopoverModule,
    NzTableModule,
    NzSpinModule,
    NzSelectModule,
    NzButtonModule
  ],
  exports: [
    NzPopoverModule,
    NzTableModule,
    NzSpinModule
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }],
})
export class SharedModuleModule { }
