import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  HostListener,
} from "@angular/core";
// import { NgxScrollEvent } from 'ngx-scroll-event';
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { TransactionStatus } from "../../../../core/model/transaction.status";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import * as moment from "moment";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import { ModalController,ToastController } from '@ionic/angular';
import * as $ from "jquery";
import { PaymentsModalComponent } from "../payments-modal/payments-modal.component";
import { TransactionsModalComponent } from "../transactions-modal/transactions-modal.component";
import { AccountService } from "src/app/core/service/account.service";
import { AuthenticationService } from "src/app/core/service/authentication.service";
@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.scss"],
})
export class TransactionsComponent implements OnInit {
  currentTransactions: any[] = [];
  spendingAvailability!: number;
  @ViewChild("invalidDateTemplate", { static: true })
  invalidDateTemplate!: TemplateRef<any>;
  constructor(
    public accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    // public modalService: BsModalService,
    private modalService: NgbModal
    // public modalController:ModalController,
  ) {



  }

  open(tid:any, trxn:any) {
    const modalRef = this.modalService.open(TransactionsModalComponent, { size: 'lg' });
    modalRef.componentInstance.oppId = this.oppId;
    modalRef.componentInstance.token = this.token;
    modalRef.componentInstance.appname = this.appname;
    modalRef.componentInstance.tid = tid;
  }

  //latest available transaction date to latest avaible
  modalRef!: BsModalRef;
  isTransactionsLoaded: boolean = false;
  fetchingTransactions: boolean = false;
  transactionHistory: any;
  transactionHistoryLength = 0;
  filteredTransactions: Array<any> = [];
  filteredTransactionsLength = 0;
  transactionStatus:any = TransactionStatus;
  myMath = Math;
  itemsPerPage = 20;
  popoverInfo: any;
  bsValue = new Date();
  bsRangeValue!: Date[];
  maxDate = new Date();
  minDate!: Date;
  currentPage: any;
  selectedDateRange: any;
  searchKey!: string;
  startDate!: Date;
  endDate!: Date;
  pageSize = 10;
  pageIndex = 1;
  transactionsPlaceholderMessage = "No transactions in the given date range";
  debounceTimer: any;
  totalRowCount = 0;
  init: boolean = true;
  token = "";
  oppId = "";
  appname = "";
  sortBy = "-trxn.recDate";
  whereFilter = "";
  filterStatus = [
    { text: "CLEARED", value: "cleared" },
    { text: "FAILED", value: "failed" },
  ];
  expandSet = new Set<number>();

  uuidv4() {
    // Public Domain/MIT
    return `${Math.random().toString(36).substr(2, 9)}_${Math.random()
      .toString(36)
      .substr(2, 9)}`;
  }

  gotoLink(item:any) {
    // routerLink="/getTransactionHistory/{{oppId}}/{{appname}}/{{token}}/payments/{{item['pid']}}"
    try {
      this.handleCancel();
    } catch (error) {

    }
    this.router.navigate(['/getTransactionHistory/' + this.oppId + '/appname/' + this.token + '/payments/' + item['pid']])
  }


  openPaymentLink(item:any) {
    // routerLink="/getTransactionHistory/{{oppId}}/{{appname}}/{{token}}/payments/{{item['pid']}}"
    try {
      this.handleCancel();
    } catch (error) {

    }
    this.router.navigate(['/getTransactionHistory/' + this.oppId + '/appname/' + this.token + '/payments/' + item['pid']])
  }

  loadDataFromServer(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    filter: string
  ): void {
    this.isTransactionsLoaded = false;
    this.fetchingTransactions = true;
    // this.randomUserService.getUsers(pageIndex, pageSize, sortField, sortOrder, filter).subscribe(data => {
    // this.accountService.revenuedTest().subscribe((data) => {});
    this.accountService
      .getTransactionHistoryIframe(
        pageIndex,
        pageSize,
        sortField,
        filter,
        this.oppId,
        "" + this.token,
        this.appname
      )
      .subscribe(
        (data) => {
          data = JSON.parse(data);
          if (
            typeof data["Result"].array.RevTrxn === "object" &&
            !Array.isArray(data["Result"].array.RevTrxn) &&
            data["Result"].array.RevTrxn !== null
          ) {
            const trxn = data["Result"].array.RevTrxn;
            data["Result"].array.RevTrxn = [trxn];
          }
          // total pages -> Math.ceil(data.array.$attributes.rowCnt/10)
          this.totalRowCount = parseInt(data.Result.array.$attributes.rowCnt);
          this.isTransactionsLoaded = true;
          this.fetchingTransactions = false;
          this.transactionHistory = data["Result"].array.RevTrxn || [];
          this.transactionHistory = this.transactionHistory.map((i:any) => {
            if (
              i.funding &&
              (i.funding.inDispute === true || i.funding.inDispute === "true")
            ) {
              i.trxn.status = "ctsDispute";
            }
            return { ...i, id: this.uuidv4() };
          });
          this.filteredTransactions = this.transactionHistory;
          this.currentTransactions = this.transactionHistory;
          this.filteredTransactionsLength = this.filteredTransactions.length;
          if (this.filteredTransactionsLength > 0) {
            if (this.init) {
              this.maxDate = new Date(
                this.filteredTransactions[0].trxn.recDate
              );
              this.minDate = new Date(
                this.maxDate.getTime() - 61 * 24 * 60 * 60 * 1000
              );
              this.bsRangeValue = [this.minDate, this.maxDate];
              this.selectedDateRange = this.bsRangeValue;
              this.onDateChange(this.selectedDateRange);
              this.init = false;
            } else {
              const maxDate = new Date(
                this.filteredTransactions[0].trxn.recDate
              );
              const minDate = new Date(
                this.maxDate.getTime() - 61 * 24 * 60 * 60 * 1000
              );
              this.bsRangeValue = [minDate, maxDate];
              this.selectedDateRange = this.bsRangeValue;
            }
          }

          this.dateLoader = false;

          if (this.tabName === "transactions") {
            if (this.activeId) {
              this.isTransactionsLoaded = true;
              setTimeout(() => {
                // $("#expand-" + this.activeId).trigger("click");
                this.showTransactionModal(this.activeId, null);
                this.isTransactionsLoaded = false;
              }, 2000);
            }
          }
        },
        (error) => {
          this.isTransactionsLoaded = true;
          this.fetchingTransactions = false;
          // this.createCase("Error", "Website Support", typeof error === "object" ? JSON.stringify(error) : error, `Transaction History Failure`);
        }
      );
  }

  trxnDetails: any = {};
  onExpandChange(id: number, checked: boolean|null): void {
    let isChecked = this.expandSet.has(id);
    this.expandSet.clear();
    if (!isChecked) {
      this.expandSet.add(id);
      this.getTransactionDetails(id);
    } else {
      this.trxnDetails = {};
      this.expandSet.delete(id);
    }
  }
  isTransactionDetailsLoaded: boolean = true;
  getTransactionDetails(id:any) {
    this.trxnDetails = {};
    this.isTransactionDetailsLoaded = false;
    this.isTransactionsLoaded = true;
    this.accountService.getTransactionDetails(id, this.oppId, this.token, this.appname).subscribe(
      (data:any) => {
        this.trxnDetails = JSON.parse(data);

        if (this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail) {
          this.trxnDetails.RevTrxnDetail.pymt.RevPymtDetail.sort(function (a: any, b: any) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.sysDate).getTime() - new Date(a.sysDate).getTime();
          });
        }

        this.isTransactionDetailsLoaded = true;
        this.isTransactionsLoaded = true;
      },
      (error:any) => {
        this.isTransactionDetailsLoaded = true;
        this.isTransactionsLoaded = true;
      }
    );
  }

  roundOffNumber(num:any) {
    return Math.round((parseFloat(num) + Number.EPSILON) * 10000) / 10000;
    // return Math.floor(num * 100 + Number.EPSILON) / 100;
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    console.log(params);
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.filter((item) => item.value !== null);
    let sortField = "";
    currentSort.forEach((element) => {
      sortField = sortField + "trxn." + element.key;
      if (element && element.value === "descend") {
        sortField = "-" + sortField + ",";
      } else {
        sortField = sortField + ",";
      }
    });
    if (sortField.substr(-1) === ",") {
      sortField = sortField.substr(0, sortField.length - 1);
    }
    !this.fetchingTransactions &&
      this.loadDataFromServer(pageIndex, pageSize, sortField, "");
  }

  isMobileView() {
    const element = document.getElementById("trans_his");

    if (element) {
      return (
        element.getBoundingClientRect().width < 480 ||
        /iphone|ipod|android|ie|blackberry|fennec/.test(
          navigator.userAgent.toLowerCase()
        )
      );
    } else{
      return;
    }
  }
  tabName = "transactions";
  activeId : any;
  dateLoader = true;
  // async
  refreshRequired=false;
  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {


      this.oppId = this.activatedRoute.snapshot.paramMap.get("oppId")||"";
      this.token = this.activatedRoute.snapshot.paramMap.get("token")||"";
      this.appname = this.activatedRoute.snapshot.paramMap.get("appname")||"";

      if (this.oppId === 'null' || this.token === 'null' || this.appname === 'null') {
        this.refreshRequired = true;
        return;
      }

      this.currentTransactions = [];
      this.filteredTransactions = [];
      this.trxnDetails = {};
      this.isTransactionsLoaded = false;
      this.expandSet.clear();

      if (this.router.url.split("/")[5]) {
        this.tabName = this.router.url.split("/")[5];
      }

      if (this.router.url.split("/")[6]) {
        this.activeId = this.router.url.split("/")[6];
      }

      this.fetchingTransactions = true;
      this.oppId = this.activatedRoute.snapshot.paramMap.get("oppId")||'';
      this.token = this.activatedRoute.snapshot.paramMap.get("token")||'';
      this.appname = this.activatedRoute.snapshot.paramMap.get("appname")||"";
      if (this.isMobileView()) {
        this.pageSize = 20;
      }

      this.fetchingTransactions = false;
      this.loadDataFromServer(this.pageIndex, this.pageSize, this.sortBy, "");

      try {
        // const res = await this.authService.isIframeTokenIdValid(this.token, this.appname);
        this.fetchingTransactions = false;
        this.loadDataFromServer(this.pageIndex, this.pageSize, this.sortBy, "");
      } catch (err) {
        this.isTransactionsLoaded = false;
        this.fetchingTransactions = false;
        this.filteredTransactions = [];
        this.currentTransactions = [];
        this.filteredTransactionsLength = 0;
        this.transactionsPlaceholderMessage =
          "Invalid Session, unable to fetch Transactions.";
      }
    });
  }
  onDateChange(dateRange?: any, dateType?:any) {
    if (dateType === "minDate" && this.selectedDateRange) {
      this.selectedDateRange[0] = dateRange;
    }
    if (dateType === "maxDate" && this.selectedDateRange) {
      this.selectedDateRange[1] = dateRange;
    }
    if (
      this.selectedDateRange &&
      this.selectedDateRange[0].getTime() > this.selectedDateRange[1].getTime()
    ) {
      //  this.modalRef = this.modalService.show(this.invalidDateTemplate);
      // this.ngOnInit();
    } else {
      if (this.transactionHistory) {
        this.filteredTransactions = this.transactionHistory.filter((t:any, i:any) => {
          var tranTime = new Date(t.trxn.recDate).getTime();
          return (
            tranTime >= this.selectedDateRange[0].getTime() &&
            tranTime <= this.selectedDateRange[1].getTime()
          );
        });
        this.currentTransactions = this.filteredTransactions.sort(
          (a, b) =>
            Number(moment(b.trxn.recDate).format("YYYYMMDD")) -
            Number(moment(a.trxn.recDate).format("YYYYMMDD"))
        );
      }
    }
  }

  searchHistory() {
    if (this.searchKey != "" && this.searchKey != null) {
      // cptyName like '%AIRPORT'
      // this.whereFilter = `cptyName like '%${this.searchKey}%'`; // `trxn.cpty.name=*${this.searchKey}*`;
      // this.whereFilter = `legalName like '%${this.searchKey}%'`;

      this.loadDataFromServer(
        this.pageIndex,
        this.pageSize,
        this.sortBy,
        this.whereFilter
      );
      // this.filteredTransactions = this.transactionHistory.filter((t, i) => {
      //   var temp = t.trxn.cpty.name.toUpperCase();
      //   //var temp = JSON.stringify(t).toUpperCase();
      //   return temp.includes(this.searchKey.toUpperCase());
      // })
      // console.log(this.filteredTransactions)
      // this.currentTransactions = this.filteredTransactions.sort((a, b) => Number(moment(b.trxn.recDate).format('YYYYMMDD')) - Number(moment(a.trxn.recDate).format('YYYYMMDD'))).slice(0, this.itemsPerPage)
    } else {
      this.loadDataFromServer(this.pageIndex, this.pageSize, this.sortBy, "");
      // this.onDateChange();
    }
  }

  debounce(func:any, delay:any) {
    return () => {
      const context = this;
      const args = arguments;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  searchHistoryDebounce() {
    this.debounce(this.searchHistory.bind(this), 500)();
  }

  gotoaccount() {
    // this.router.navigate(['/dashboard']);
  }
  pageChanged(event: PageChangedEvent) {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.currentTransactions = this.transactionHistory.slice(
      startItem,
      endItem
    );
  }

  loadDataOnScroll() {
    this.pageSize = this.pageSize + 20;
    this.loadDataFromServer(this.pageIndex, this.pageSize, this.sortBy, "");
  }

  @HostListener("window:scroll", ["$event.target"]) // for window scroll events
  scroll(e:any) {
    if (this.isMobileView() && this.pageSize < this.totalRowCount) {
      let scroll = e.scrollingElement.scrollTop;
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        console.log("this is the scroll position", scroll);
        !this.fetchingTransactions &&
          this.debounce(this.loadDataOnScroll.bind(this), 500)();
      }
    }
  }
  isVisible = false;
  isOkLoading = false;
  modalData = null;

  showTransactionModal(id:any, transaction:any) {
    try {
      this.handleCancel();
      this.handlePaymentsCancel();
    } catch (error) {

    }
    this.isVisible = true;
    // this.modalData = transaction;
    this.getTransactionDetails(id);
  }

  indicator: boolean = true;

  handleCancel(): void {
    this.isVisible = false;
  }




  pymtDetails: any = {};

  getPaymentDetails(id:any) {
    this.pymtDetails = {};
    this.isTransactionDetailsLoaded = false;
    this.isTransactionsLoaded = true;
    this.accountService.getPaymentDetails(id, this.oppId, this.token, this.appname).subscribe(
      (data:any) => {
        this.pymtDetails = JSON.parse(data);
        if (this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail) {
          this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail.sort(function (a: any, b: any) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.sysDate).getTime() - new Date(a.sysDate).getTime();
          });
        }
        this.isTransactionDetailsLoaded = true;
        this.isTransactionsLoaded = true;
      },
      (error:any) => {
        this.isTransactionDetailsLoaded = true;
        this.isTransactionsLoaded = true;
      }
    );
  }

  isPaymentsModalVisible = false;

  showPaymentsModal(id:any, transaction:any) {
    try {
      this.handleCancel();
      this.handlePaymentsCancel();
    } catch (error) {

    }
    this.isPaymentsModalVisible = true;
    this.getPaymentDetails(id);
  }

  handlePaymentsCancel(): void {
    this.isPaymentsModalVisible = false;
  }




  openPaymentModal(pid:any, trxn:any) {
    const modalRef = this.modalService.open(PaymentsModalComponent, { size: 'lg' });
    modalRef.componentInstance.oppId = this.oppId;
    modalRef.componentInstance.token = this.token;
    modalRef.componentInstance.appname = this.appname;
    modalRef.componentInstance.pid = pid;
  }


  openTransactionModal(tid:any, trxn:any) {
    const modalRef = this.modalService.open(TransactionsModalComponent, { size: 'lg' });
    modalRef.componentInstance.oppId = this.oppId;
    modalRef.componentInstance.token = this.token;
    modalRef.componentInstance.appname = this.appname;
    modalRef.componentInstance.tid = tid;
  }
  searchItem = null;
}
