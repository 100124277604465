import { Component, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertController } from '@ionic/angular';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-maintainance',
  templateUrl: './maintainance.component.html',
  styleUrls: ['./maintainance.component.css']
})
export class MaintainanceComponent implements AfterViewInit {

  platform = localStorage.getItem('platform') || 'web';  // Default to 'web' if undefined
  maintainanceIframeURL: string = "";

  constructor(public alertController: AlertController) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const MAINTAINANCE_URL = environment.appUrl;
      if (!MAINTAINANCE_URL) {
        console.warn('Maintenance URL is not configured in the environment.');
        return;
      }
      this.maintainanceIframeURL = MAINTAINANCE_URL;
      try {
        const iframe = document.getElementById('maintainanceIframe') as HTMLIFrameElement;
        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.location.replace(this.maintainanceIframeURL);
        }
      } catch (error) {
        console.error('Error updating iframe:', error);
      }
    }, 2500);

  }

  showExitConfirm() {
    this.alertController
      .create({
        header: '',
        message: 'Do you want to close the app?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay',
            role: 'cancel',
            handler: () => {
              console.log('Application exit prevented!');
            },
          },
          {
            text: 'Exit',
            handler: () => {
              App.exitApp();  // Use Capacitor's App plugin for consistent behavior
            },
          },
        ],
      })
      .then((alert) => alert.present());
  }
}

