import { Injectable, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertController, Platform } from '@ionic/angular';
import { RateApp } from 'capacitor-rate-app';
import { Storage } from '@ionic/storage-angular';

import { Capacitor } from '@capacitor/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpBackend,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, finalize, map, share, take, tap } from "rxjs/operators";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { LoginFormErrorModalComponent } from "../../auth-layout/login-form-error-modal/login-form-error-modal.component";
// import { ConformationModalComponent } from "../../shared-new/conformation-modal/conformation-modal.component";
import { AccountService } from "./account.service";
import { BrowserLogger } from "../browser-logger.service";
import { RumLogger } from "../rum-logger.service";
import { ConformationModalComponent } from "src/app/shared/conformation-modal/conformation-modal.component";
import { CookieService } from "ngx-cookie-service";
import { AuthStorageService } from "src/app/auth-layout/auth/auth-storage.service";

declare global {
  interface Window {
    FS: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  modalRef!: BsModalRef;
  RumLoggerObj$: any = null;
  loggedInSubject = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedInSubject.asObservable();
  updatePwdUrl = `${environment.baseUrl}v1/authentication/updatePassword`;
  forgotPwdUrl = `${environment.baseUrl}v1/authentication/forgotPassword`;
  appName = environment.appName;

  //bypassing the global http interceptor
  //http: HttpClient;
  constructor(
    public alertController: AlertController,
    private storage: Storage,
    private platform: Platform,
     private authStorage: AuthStorageService,
    public accountService: AccountService,
    private router: Router,
    private http: HttpClient,
    private actvatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    handler: HttpBackend,

  ) {
    this.loginurl = environment.baseUrl || "";
    //bypassing the global http interceptor
    //this.http = new HttpClient(handler);


  }
  loggedInEvent = new EventEmitter();
  loginError = new BehaviorSubject(false);
  loginurl: string;
  currentPath!: string;
  loggedIn() {
    this.loggedInEvent.emit(true);
  }
  loggedOut() {
    this.loggedInEvent.emit(false);
  }


  private mfaToken$ = new BehaviorSubject<string | null>(null);
  private oobCode$ = new BehaviorSubject<string | null>(null);
  private email$ = new BehaviorSubject<string | null>(null);

  private cache: any;
  private cachedObservable: Observable<any> | undefined;

  setMfaDetails(email: string, mfaToken: string, oobCode: string) {
    this.email$.next(email);
    this.mfaToken$.next(mfaToken);
    this.oobCode$.next(oobCode);
  }

  getMfaDetails(): { email: string | null, mfaToken: string | null, oobCode: string | null } {
    return {
      email: this.email$.value,
      mfaToken: this.mfaToken$.value,
      oobCode: this.oobCode$.value
    };
  }

  getMfaToken$(): Observable<string | null> {
    return this.mfaToken$.asObservable();
  }

  getOobCode$(): Observable<string | null> {
    return this.oobCode$.asObservable();
  }

  getEmail$(): Observable<string | null> {
    return this.email$.asObservable();
  }

  isTokenIdValid(): Observable<any> {
    let observable: Observable<any>;
    const id_token = window.localStorage.getItem('id_token') || JSON.parse(window.localStorage.getItem("token") || "").id_token;
    const appName = environment.appName;
    // const id_token = "";
    // const headers = { Authorization: "Bearer " + id_token, appName: appName };
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + id_token, "appName": appName, 'skip_interceptor': 'false' })
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    var loginurl = environment.baseUrl;
    this.cachedObservable = this.http
      .get(`${loginurl}v1/authentication/validateToken`, {
        headers,
      })
      .pipe(
        tap((res) => (this.cache = res)),
        share(),
        finalize(() => (this.cachedObservable))
      );
    observable = this.cachedObservable;

    return observable;
  }
  isIframeTokenIdValid(id_token: any, appName: any) {
    return new Promise((resove, rej) => {
      const headers = new HttpHeaders()
        .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
        .append('appname', appName)
        .append("Authorization", "Bearer " + id_token)
      // .append("skip_interceptor_rum_logs", 'true');
      this.http.post(`${environment.baseUrl}v1/revenued/getConfig`, {}, { headers }).toPromise().then((res) => {
        var loginurl = environment.baseUrl;
        const headers = { 'Authorization': 'Bearer ' + id_token, "appName": appName, skip_interceptor: "true" }
        this.http.get(`${loginurl}v1/authentication/validateToken`, {
          headers
        }).subscribe((res) => { resove(res) }, () => { rej() })
      })
    });
  }
  getConfigs() {
    let appName = environment.appName;
    var idToken = JSON.parse(window.localStorage.getItem("token") || '').id_token;
    const headers = new HttpHeaders()
      .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
      .append('appname', appName)
      .append("Authorization", "Bearer " + idToken)
    // .append("skip_interceptor", 'true')
    // .append("skip_interceptor_rum_logs", 'true');
    this.http.post(`${environment.baseUrl}v1/revenued/getConfig`, {}, { headers }).toPromise().then((res: any) => {
      var loginurl = environment.baseUrl;
      window.localStorage.setItem("loginError", '');
      window.localStorage.setItem('KYC_IFRAME_URL', res['KYC_IFRAME_URL']);
      window.localStorage.setItem("ENABLE_GLOBAL_HTTP_INTERCEPTOR", res["ENABLE_GLOBAL_HTTP_INTERCEPTOR"]);
      window.localStorage.setItem("DATADOG_OPTION_SAMPLE_RATE", res["DATADOG_OPTION_SAMPLE_RATE"]);
      window.localStorage.setItem("ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING", res["ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING"]);
      window.localStorage.setItem("DATADOG_HOST_NAME", res["DATADOG_HOST_NAME"]);
      this.appName = environment.appName;
      window.localStorage.setItem("appName", this.appName);
      window.localStorage.setItem("COVERDASH_LICENSE", res['COVERDASH_LICENSE']);
      window.localStorage.setItem("COVERDASH_QUOTES_URL", res['COVERDASH_QUOTES_URL']);
      this.loginurl = loginurl;
    });
  }

  cacheData: any = undefined;

  fetchUserCache(email: any, token: any) {
    const id_token = window.localStorage.getItem('id_token');
    const appName = window.localStorage.getItem("appName") || "";
    const headers = { 'skip_interceptor': 'true', 'skip_interceptor_rum_logs': 'true', "appName": appName, 'Authorization': 'Bearer ' + id_token };
    this.http.post(`${environment.baseUrl}v1/cache/getUserCacheData`, { email, token }, { headers })
      .subscribe((res: any) => {
        if (res) {
          if (res.status === 'success') {
            if (res.data) {
              this.cacheData = res.data;
              this.accountService.updateAccountStatementState(this.cacheData.spendingLimit, this.cacheData.discountedBalance, this.cacheData.pendingAmount, this.cacheData.spendingAvailability, this.cacheData.dailySpendLimit, this.cacheData.ministatement, this.cacheData.currentTransactions, this.cacheData.paymentCaledarHistoryData);
            }
          }
        }
      },
        (err) => {
          console.log(err);
        }
      );
  }

  BrowserLoggerObj: any = null;
  RumLoggerObj: any = null;
  appLogin(cred: any, failed: any, loggedIn: any, redirectURL: any, isOtpLogin = false, isMFAOtpLogin = false) {


    this.appName = environment.appName;
    this.loginurl = environment.baseUrl;
    const headers = { 'appName': this.appName, skip_interceptor: "true" };


    let loginLink = this.loginurl + "v1/authentication/loginUser";
    if (isOtpLogin) {
      loginLink = this.loginurl + "v1/authentication/verifyEmailOtp";
    }

    if (isMFAOtpLogin) {
      loginLink = this.loginurl + "v1/authentication/verifyMFAChallengeToken";
    }
    this.http.post(`${loginLink}`, { ...cred.value, email: cred.value.email.trim() }, { headers })
      .subscribe((res: any) => {
        let appName = environment.appName;
        var idToken = res['id_token'];

        const headers = new HttpHeaders()
          .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
          .append('appname', appName)
          .append("Authorization", "Bearer " + idToken)
        // .append("skip_interceptor", 'true')
        // .append("skip_interceptor_rum_logs", 'true');
        this.http.post(`${environment.baseUrl}v1/revenued/getConfig`, {}, { headers }).toPromise().then((res: any) => {
          var loginurl = this.loginurl;
          window.localStorage.setItem("loginError", '');
          window.localStorage.setItem('KYC_IFRAME_URL', res['KYC_IFRAME_URL']);
          window.localStorage.setItem("ENABLE_GLOBAL_HTTP_INTERCEPTOR", res["ENABLE_GLOBAL_HTTP_INTERCEPTOR"]);
          window.localStorage.setItem("DATADOG_OPTION_SAMPLE_RATE", res["DATADOG_OPTION_SAMPLE_RATE"]);
          window.localStorage.setItem("ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING", res["ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING"]);
          window.localStorage.setItem("DATADOG_HOST_NAME", res["DATADOG_HOST_NAME"]);

          window.localStorage.setItem("DISABLED_DRAW", res['DISABLED_DRAW']);
          window.localStorage.setItem("DISABLED_PREPAY", res['DISABLED_PREPAY']);
          window.localStorage.setItem("DISABLED_PAYMENT_ADJUSTMENT", res['DISABLED_PAYMENT_ADJUSTMENT']);
          window.localStorage.setItem("DISABLED_REWARDS", res['DISABLED_REWARDS']);
          window.localStorage.setItem("COVERDASH_LICENSE", res['COVERDASH_LICENSE']);
          window.localStorage.setItem("COVERDASH_QUOTES_URL", res['COVERDASH_QUOTES_URL']);
          window.localStorage.setItem("PAYMENT_CALENDER_INFO_TEXT", res['PAYMENT_CALENDER_INFO_TEXT']);
        });
        this.loggedInSubject.next(false);
        let configs = { ...res };
        if (res['access_token']) {
          const headers = { 'Authorization': 'Bearer ' + res['id_token'], 'appName': this.appName };
          window.localStorage.setItem('loginurl', this.loginurl)
          window.localStorage.setItem('id_token', res['id_token'])

          this.http.post(`${this.loginurl}v1/authentication/getUserDetails`, { email: cred.value.email.trim() }, {
            headers
          }).subscribe((details: any) => {


            /*sets user data to rum logs*/


            try {
              this.BrowserLoggerObj = new BrowserLogger();
              this.RumLoggerObj = new RumLogger();
              this.RumLoggerObj$ = this.RumLoggerObj;
            } catch (error) {
              //do nothing
            }

            try {
              if (this.RumLoggerObj$) {
                this.RumLoggerObj$.setCustomUser({
                  "displayName": details['name'],
                  "email": details['email'],
                  "appId": details['app_metadata'].merchAppId,
                  "oppId": details['app_metadata'].opportunityId,
                  "cardId": details['app_metadata'].cardId,
                  "name": details['name'],
                  "id": details['app_metadata'].opportunityId,
                  "platform": (window.localStorage.getItem('platform') || 'browser')
                });


                this.RumLoggerObj$.setCustomAction(JSON.stringify({
                  "displayName": details['name'],
                  "email": details['email'],
                  "appId": details['app_metadata'].merchAppId,
                  "oppId": details['app_metadata'].opportunityId,
                  "cardId": details['app_metadata'].cardId,
                  "name": details['name'],
                  "id": details['app_metadata'].opportunityId,
                  "platform": (window.localStorage.getItem('platform') || 'browser')
                }), {
                  "displayName": details['name'],
                  "email": details['email'],
                  "appId": details['app_metadata'].merchAppId,
                  "oppId": details['app_metadata'].opportunityId,
                  "cardId": details['app_metadata'].cardId,
                  "name": details['name'],
                  "id": details['app_metadata'].opportunityId,
                  "platform": (window.localStorage.getItem('platform') || 'browser')
                });


              }
            } catch (error) {
              //do nothing
            }
            /*sets user data to rum logs*/



            window.localStorage.setItem('oppId', details['app_metadata'].opportunityId)
            window.localStorage.setItem('displayName', details['name'])
            window.localStorage.setItem('login_ts', new Date().getTime().toString());
            window.localStorage.setItem('email', details['email'])
            window.localStorage.setItem('name', details['name'])
            window.localStorage.setItem('cardId', details['app_metadata'].cardId)
            window.localStorage.setItem('coverdashBusinessId', details['app_metadata'].coverdashBusinessId ?? '')
            window.localStorage.setItem('contId', details['app_metadata'].contId)
            window.localStorage.setItem('appId', details['app_metadata'].merchAppId)
            window.localStorage.setItem('logins_count', details['logins_count'] ? details['logins_count'].toString() : 'NA')
            window.localStorage.setItem('accountOwner', 'true');
            window.localStorage.setItem('Grace_Window_Days', configs['Grace_Window_Days']);
            window.localStorage.setItem('Post_Grace_Premium', configs['Post_Grace_Premium']);
            window.localStorage.setItem('US_HOLIDAYS', configs['US_HOLIDAYS']);
            if (details["app_metadata"].isOperator) {
              window.localStorage.setItem("userMode", "operator");
            } else {
              window.localStorage.setItem("userMode", "general");
            }
            window.localStorage.setItem("token", JSON.stringify(res));
            window.localStorage.setItem("appName", this.appName);


            /*cache implementation*/
            if (cred.value.email.trim() && res['access_token'])
              this.fetchUserCache(cred.value.email.trim(), res['access_token']);
            /*cache implementation*/




            this.accountService.getAccountSummary().subscribe((res: any) => {

              try {
                localStorage.setItem('legalName', res.RevAccountSummary.summary.legalName)
                res = JSON.parse(res);
                let isCollection = res.RevAccountSummary.summary?.inCollection || 'false';
                if (isCollection === 'true') {
                  this.router.navigate(['/dashboard/collection']);
                } else {

                  let platformName = window.localStorage.getItem('platform') || 'browser';

                  if (redirectURL && platformName !== 'Android' && platformName !== 'iOS') {
                    if (redirectURL === '/pricing') {
                      this.router.navigate(['/dashboard/prepay-cal']);
                    } else {
                      this.router.navigate([redirectURL]);
                    }
                  } else {
                    if (localStorage.getItem('skipIntro') && localStorage.getItem('skipIntro') !== "true" && localStorage.getItem('environment') !== 'web' && localStorage.getItem('environment')) {
                      console.log(localStorage.getItem('skipIntro'))
                      this.router.navigate(['/walkthrough'])
                    } else {
                      this.router.navigate(["/dashboard"]);
                    }
                  }

                }
              } catch (error) {
                this.router.navigate(["/dashboard"]);
              }

            }, (error: any) => {
              this.router.navigate(["/dashboard"]);
            });

          })
        } else {
          console.log("Errro");
          failed = true;
          this.loggedInSubject.next(false);
          this.loginError.next(true);
        }
      },
        (err) => {

          if (err.error.error === 'mfa_required') {
            window.localStorage.setItem('mfa_token', err.error.mfa_token);
            window.localStorage.setItem('oob_code', err.error.oob_code)
            this.router.navigate(["/mfaotplogin", cred.value.email.trim(), true]);
            return;
          }

          failed = true;
          loggedIn = false;
          let errMsg = "Something went wrong!"
          if (err.error.errorMessage) {
            errMsg = err.error.errorMessage
          } else if (err.error.error) {
            errMsg = err.error.error
          }
          window.localStorage.setItem("loginError", errMsg);
          this.loginError.next(true);
        }
      )



  }


  applogin(cred: any, isOtpLogin = false, isMFAOtpLogin = false) {
    const loginLink = this.getLoginLink(isOtpLogin, isMFAOtpLogin);
    const headers = { 'appName': environment.appName, skip_interceptor: 'true' };

    return this.http.post(`${loginLink}`, { ...cred.value, email: cred.value.email.trim() }, { headers });
  }

  getConfig() {
    var idToken = JSON.parse(window.localStorage.getItem("token") || '').id_token;
    const headers = new HttpHeaders()
      .append('Authorization', `Bearer ${idToken}`)
      .append('appname', environment.appName)
    // .append('skip_interceptor', 'true');

    return this.http.post(`${environment.baseUrl}v1/revenued/getConfig`, {}, { headers });
  }

  loginUserWithMFA(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders()
      .append(
        'REVENUED_MIDDLEWARE_TOKEN',
        environment.REVENUED_MIDDLEWARE_TOKEN
      )
      .append('appname', environment.appName)
      .append('skip_interceptor', 'true');
    // const mockResponse = {
    //   success: true,
    //   mfa_required: true,
    //   mfa_token:
    //     'Fe26.2*SERVER_1740717623*dd959f579ea6e937c838a044332b18fcba0c0eceefcf6e0066182f217e9074b0*ElxSSpHOpoWYhAYE2gcXnw*gjhaVeF5h35IYyCkXZHWkVCWrqtVWmlPcNM2AZWGQkggEWzolBucHF2MPyFwo7qgnI-ZxG5BIfzhBJBBLKuOD20IB2lvWQGoHlu6d_3ihIpmAZSpNfnyhribNYbAMA7E665sgnR1M7Z1WIck8FMa0OTN5za-IrNyABl6b4TdPVpfMcVibyEMimFwKGfqTu39nUYNxE6ak3dS_c_kJYp4qd88ULp8rd9I17zkQF_KO1KKwy-ZlISUYwFAKGJ-O0tkW2tmswkPhpeQcl53qCgYhzc846lVRDBort_hSDIhqopWtN01mAZlGYvxb-sY5Z6GsjhdraC7G-XqGa9x2w8mccnTvLtZuK-WCTbuqPh0XCJsY-yp7VPv-MJFGdRIdrXHZ3DtOFsdYXIkpswSfUPbzpz1MmpOiFKPx9Fk9oYfE7NnyvO5HQ3L58Y3No76MSsuAyxyN7KQQjbMRTJIMagyKwNehOCisMhdxfXfv6oDeJGFomqBPHdN9eynKj7hiFKV_irp00qT4cT_zXq4LNwYMcvYGi1IqD1mLJCOQtmFgV3kbKhop0Z4Xl38xnIs3441lYdrO5M06BXYChKMC5TYhxV9ZFgJIBdN5Ji50NtzA0VK6MIUKuEi7m7QQtzEh0yRrjYSE2HoYIWwFX6iDpZzljuF7Bvtl8lUVT2Qw3FrHE-IAVqVHkusDrRG5AmtcCLrS4Mmxld-9oIow_0KS_SwlTTlqNbY3N1-22i7oFlD1x_PmfODvXDSyX1KCWCkKNsNBXV-DZwnR_OV8ougxgRVc071fyquo5kacX7Y1Xyi4npAnWFeAhhxxxAmQ5foui7slCl1I9eZrLXY7hOhs6CphRovD6618gpuRYGs6xgSyTrCDuNfjqCiG09NE2165MLZTsgY-FLx70I9g4lqz1W7Wfy2ew6B8rESzSWbc8LyFAoDrv3ZAWqJImCp-5J29vb8p_XMLjDwYDggGsvWXu_Bcdj2CuLxfe5h5rJ1n2JzYWa2e8-QFI0jannFGcjlc5OZ16Rv9EHiVh09lCpSnfKoAWPl8wzv4vEgBZTfCSRfPeUXUNvqLnXNe9QPheXhnihG2IEyk2D-cr3EzJDUl6SD-pByfXAPdBcUy0zo2VYhzuT9e0P_wpgRxlFLFNPBBjLoSeLCY6a2cIRqR7r8ZvGGgwRBfRS25WBbXUTCjj5tpqAIJtM98Ez-DZUEYkjSfXpfctMKY-wxQHGjI0z-AshLjbvb8frWaFvaKOWfgxYqzCcV0CFSsiH1Oftf7gk3JcJZpAATBmqYUAMMtZ8rx9wx1r_X_eBTEfdPBS3UPnzfo3TRK8y747wcYur6zmZKcgvPlfiyStiGUJU2R3SUvswdCPYMuTEfwrdg0BsCKjhYFsTa4jed0MVuQU_62YDSIjm_KA6WIv7DnkUQ3uQa3ZV9tkV5XIf2JhEicJmdetg14zdmtSAfSyzzLLc1hAKYIQpaAOp3-p_wDW_tuM24ILpDBHfMEo39mGTWRLIQwqLNif1u2qDMbkkSTz9hEgvw7cMyR7UxL4VO5KnCJqK6tHaVmjCYpxWFHqu0NoaRaaJqCCgyMewlSnaVW3IktCvHaHAIonct0iEqCr8kcZT38-6ejg*1741584276850*f03b3b660e9f5159775fac2aa6449e2fd425e882e287a8a760aa92987313bef5*c5uD7Am6P-eQRx_bRFaLiyr-i9hkPA50OT4ngCpJ7xA',
    //   authenticator_types: [
    //     {
    //       id: 'email|dev_MWDd0Znn1wj0O1zp',
    //       authenticator_type: 'oob',
    //       active: true,
    //       oob_channel: 'email',
    //       name: 'kirt**********@reve********',
    //     },
    //   ],
    //   enrolled_authenticators: {
    //     authenticator_type: 'oob',
    //     binding_method: 'prompt',
    //     oob_channel: 'email',
    //     oob_code:
    //       'Fe26.2*SERVER_1740717623*ec1a7ddcc928e324c96feb01a36759ddaa44f1fb3070e437c187196ee1fa2f44*CB1mKOcpvI847NfbPklFTA*sWj_l5hZ9dtTvLc2tDWbKCoEdOI58VL3PGHptvkw_FYjiCx-_2fExplfqoRfXSzoCzWr0zTFv0FAAkFzfNmkmv_9bPe6VWoHE6r4eBviMyqGBf9-tjtwZ9ODlmc20L9q6dr63JrVbDWN_eSJyZ5mTAcimTfdIM0jXAJrqR2JD6TNAJaP9vr3erNPYqTfYcH6gXoJ3i3royKwcSOXbVJUrZVVhZCGdNhaOh9WXyhbg786nuEo5XlbQWy0WDfOgF382BTEwT2r3nm7Ry9U5RayKt_5LCUUYViZTo3mpegQHhtVL0WmsyXtRfNu6zKcJbzH2Dk28P2h71SkOkn1nCgjCDJH5nNikBInbYpdWvSm2zI*1741584278248*3e5318ea3bbe1e95adfa11529ca90fe1c94e83f22dac687c58fe0036a14b8c5e*_8T4fEq2ZgylwtRt3-yNH9AZtYoXWKo6EWxGO3O1fKk',
    //   },
    // };
    const body = {
      email: email,
      password: password
    };
    // return of(mockResponse);
    return this.http.post<any>(`${environment.baseUrl}v1/authentication/loginUserWith2FA`, body, { headers });
  }


  resend2FAChallenge(mfaToken: string): Observable<any> {
    const headers = new HttpHeaders()
      .append('REVENUED_MIDDLEWARE_TOKEN', environment.REVENUED_MIDDLEWARE_TOKEN)
      .append('appname', environment.appName)
      .append('skip_interceptor', 'true');

    const body = {
      mfa_token: mfaToken
    };

    return this.http.post<any>(`${environment.baseUrl}v1/authentication/resend2faChallenge`, body, { headers });
  }


  verifyOtp(email: string, mfaToken: string, oobCode: string, bindingCode: string): Observable<any> {
    const headers = new HttpHeaders()
      .append('REVENUED_MIDDLEWARE_TOKEN', environment.REVENUED_MIDDLEWARE_TOKEN)
      .append('appname', environment.appName)

    const requestBody = {
      email: email,
      mfa_token: mfaToken,
      binding_code: bindingCode,
      oob_code: oobCode
    };

    return this.http.post<any>(`${environment.baseUrl}v1/authentication/verify2FAChallenge`, requestBody, { headers });
  }

  sendEmailOtpForFeatureUnlock(): Observable<any> {
    const email = window.localStorage.getItem('email') || '';
    const headers = {
      appname: environment.appName,
      REVENUED_MIDDLEWARE_TOKEN: environment.REVENUED_MIDDLEWARE_TOKEN,
      skip_interceptor: "true",
    };
    const body = { email: email };

    return this.http.post<any>(`${environment.baseUrl}v1/authentication/sendEmailOtpForFeatureUnlock`, body, { headers });
  }

  verifyEmailOtpForFeatureUnlock(otp: string): Observable<any> {
    const email = window.localStorage.getItem('email') || '';
    const headers = {
      appname: environment.appName,
      REVENUED_MIDDLEWARE_TOKEN: environment.REVENUED_MIDDLEWARE_TOKEN,
      skip_interceptor: "true",
    };
    const body = {
      otp: otp,
      email: email
    };

    return this.http.post<any>(`${environment.baseUrl}v1/authentication/verifyEmailOtpForFeatureUnlock`, body, { headers })
  }

  getUserDetails(cred: any) {
    const email = cred.email.trim();
    var idToken = JSON.parse(window.localStorage.getItem("token") || '').id_token;
    const headers = new HttpHeaders()
      .append('Authorization', `Bearer ${idToken}`)
      .append('appName', environment.appName);

    return this.http.post(`${environment.baseUrl}v1/authentication/getUserDetails`, { email }, { headers });
  }

  private getLoginLink(isOtpLogin: boolean, isMFAOtpLogin: boolean) {
    if (isOtpLogin) {
      return environment.baseUrl + "v1/authentication/verifyEmailOtp";
    }
    if (isMFAOtpLogin) {
      return environment.baseUrl + "v1/authentication/verifyMFAChallengeToken";
    }
    return environment.baseUrl + "v1/authentication/loginUser";
  }
  getClientEmails(body: any, headers: any) {
    //handle-from-global-interceptor
    return this.http.post(
      `${environment.baseUrl}v1/authentication/getClientEmails`,
      body,
      {
        headers,
      }
    );
  }
  getUsersOfOperator(body: any, headers: any) {
    //handle-from-global-interceptor
    return this.http.post(`${environment.baseUrl}v1/authentication/getUsersOfOperator`, body, {
      headers,
    });
  }
  shouldShowDuns() {
    const idToken = JSON.parse(window.localStorage.getItem('token') ?? '')?.id_token;
    const email = window.localStorage.getItem('email');

    const headers = {
      appname: environment.appName,
      Authorization: "Bearer " + idToken,
      skip_interceptor: "true",
    };
    //handle-from-global-interceptor
    return this.http.post(`${environment.baseUrl}v1/authentication/issetDuns`, { email },
      { headers },
    )
  };
  updatePwd(cred: any) {
    const appName = environment.appName;
    const headers = new HttpHeaders({ skip_interceptor: "true", appname: appName });
    //handle-from-global-interceptor
    return this.http.post(this.updatePwdUrl, cred, {
      headers,
    });
  }
  updateMetadata(data: any) {
    var oppId = window.localStorage.getItem("oppId");
    var idToken = JSON.parse(window.localStorage.getItem("token") || '').id_token;


    const headers = new HttpHeaders({
      appname: environment.appName,
      Authorization: "Bearer " + idToken,
      skip_interceptor: "true",
    })
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .post<{ results: any[] }>(`${this.loginurl}v1/authentication/updateUserMetaData`, data,
        {
          headers,
        })
      .pipe(catchError(this.handleError.bind(this)));
  }
  // setPwd(pwd){
  //   console.log("New created password details::",pwd)
  //   return this.http.post(this.updatePwdUrl,pwd);
  // }
  forgotPwd(email: any) {
    email.email = email.email.toLowerCase().trim()

    const headers = new HttpHeaders({ appname: environment.appName, skip_interceptor: "true" });
    //handle-from-global-interceptor
    return this.http.post(`${environment.baseUrl}v1/authentication/forgotPassword`, email, {
      headers,
    });
  }

  sendEmailOtp(email: any) {
    let data = {
      email: email.toLowerCase().trim()
    }
    const headers = { appName: this.appName, skip_interceptor: "true" };
    return this.http.post(`${environment.baseUrl}v1/authentication/sendEmailOtp`, data, {
      headers,
    });
  }

  checkPasswordToken(details: any) {

    // this.currentPath = path;
    const headers = new HttpHeaders({ appname: environment.appName, skip_interceptor: 'true' });
    // const headers = { skip_interceptor: "true" };
    //custom-error-handling (skip global interceptor using header tag - skip_interceptor : true
    return this.http
      .post(`${environment.baseUrl}v1/authentication/validateEmailVerificationToken`, details, {
        headers,
      })
      .pipe(
        catchError(error => { throw error; }),
        map((res) => res)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // alert("Please check your Internet Connection. Something went wrong")
      const initialState = {
        title: "Error",
        message: "Please check your Internet Connection. Something went wrong",
      };
      this.openModal(initialState);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      if (this.currentPath === "verifyEmail") {
        // alert("Password has been created or link has expired. Please contact Revenued Support.")
        const initialState = {
          title: "",
          message:
            "Password has been created or link has expired. Please contact Revenued Support.",
        };
        this.openModal(initialState);
      }
      if (this.currentPath === "passwordReset") {
        // alert("Link has expired. Please request another reset link using Forgot Password.")
        const initialState = {
          title: "",
          message:
            "Link has expired. Please request another reset link using Forgot Password.",
        };
        this.openModal(initialState);
      }

      // this.router.navigate(["/login"]);
      // console.log(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      error.error ||
      error.message ||
      "Something bad happened; please try again later."
    );
  }
  openModal(initialState: any) {
    this.modalService.hide(100);
    this.modalRef = this.modalService.show(LoginFormErrorModalComponent, {
      initialState,
    });
    this.modalRef.content.closeBtnName = "Close";
  }
  openConformationModalAndLogout() {
    this.modalService.hide(100);
    this.modalRef = this.modalService.show(ConformationModalComponent);
    this.modalRef.content.closeBtnName = "Close";
    this.modalRef.content.onClose.subscribe((result: any) => {
      console.log("results", result);
      if (result) {
        console.log("527");

        this.platform.ready().then(async () => {
          console.log("530");

          if (Capacitor.isNativePlatform()) {
            console.log("533");

            this.showCustomRatingDialog();

          }

        });

        var skipIntro = localStorage.getItem('skipIntro');
        window.localStorage.removeItem("token");
        window.localStorage.clear();

        if (skipIntro) {
          localStorage.setItem('skipIntro', 'true');
        }
        // window.localStorage.removeItem('token');
        this.loginError.next(false);
        this.router.navigate(["/"]);
      }
    });
  }

  // Function to get app usage count from storage
  async getAppUsageCount(): Promise<number> {
    const appUsageCount = await this.storage.get('appUsageCount');
    return appUsageCount ? appUsageCount : 0; // Default to 0 if no value
  }

  // Function to increment app usage count
  async incrementAppUsageCount() {
    let appUsageCount = await this.getAppUsageCount();
    appUsageCount += 1;
    await this.storage.set('appUsageCount', appUsageCount); // Correct usage with this.storage
  }

  // Custom prompt with the same message
  async showCustomRatingDialog() {
    const alert = await this.alertController.create({
      header: 'Do you enjoy Revenued App?',
      message: 'If you enjoy using Revenued App, would you mind taking a moment to rate it? Thanks so much!',
      buttons: [
        {
          text: 'No, Thanks',
          role: 'cancel',
        },
        {
          text: 'Remind Me Later',
          handler: () => {
            console.log('User clicked Remind Me Later');
          },
        },
        {
          text: 'Rate It Now',
          handler: () => {
            this.showRatingPrompt();
          },
        },
      ],
    });

    await alert.present();
  }

  // Show the native rate app prompt
  showRatingPrompt() {
    RateApp.requestReview()
      .then(() => {
        console.log('Rating prompt displayed');
      })
      .catch((error) => {
        console.error('Error displaying rating prompt', error);
      });
  }

  async afterMFALogin(res: any) {

    // if (this.loginForm.valid) {
    // this.loggedIn = true;

    //todo
    const params = this.actvatedRoute.snapshot.queryParams;
    const redirectURL = params['redirectURL'];
    const platformName = window.localStorage.getItem('platform') || 'browser';
    // const email = window.localStorage.getItem('email')
    try {
     var email = await this.authStorage.getAuthToken('email');   
    } catch(err){
      console.log(err)
    }
   // this.failedLogin = false;

    // this.authService.appLogin(this.loginForm, this.failedLogin, this.loggedIn, redirectURL)

    // this.authService.applogin(this.loginForm).subscribe({
    // next: (res: any) => {
    window.localStorage.setItem("token", JSON.stringify(res));
    this.getConfig().subscribe({
      next: (configRes: any) => {
        window.localStorage.setItem("loginError", '');
        window.localStorage.setItem('KYC_IFRAME_URL', configRes['KYC_IFRAME_URL']);
        window.localStorage.setItem("ENABLE_GLOBAL_HTTP_INTERCEPTOR", configRes["ENABLE_GLOBAL_HTTP_INTERCEPTOR"]);
        window.localStorage.setItem("DATADOG_OPTION_SAMPLE_RATE", configRes["DATADOG_OPTION_SAMPLE_RATE"]);
        window.localStorage.setItem("ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING", configRes["ENABLE_DATADOG_RUM_SESSION_REPLAY_RECORDING"]);
        window.localStorage.setItem("DATADOG_HOST_NAME", configRes["DATADOG_HOST_NAME"]);

        window.localStorage.setItem("DISABLED_DRAW", configRes['DISABLED_DRAW']);
        window.localStorage.setItem("DISABLED_PREPAY", configRes['DISABLED_PREPAY']);
        window.localStorage.setItem("DISABLED_PAYMENT_ADJUSTMENT", configRes['DISABLED_PAYMENT_ADJUSTMENT']);
        window.localStorage.setItem("DISABLED_REWARDS", configRes['DISABLED_REWARDS']);
        window.localStorage.setItem("COVERDASH_LICENSE", configRes['COVERDASH_LICENSE']);
        window.localStorage.setItem("COVERDASH_QUOTES_URL", configRes['COVERDASH_QUOTES_URL']);
        window.localStorage.setItem("PAYMENT_CALENDER_INFO_TEXT", configRes['PAYMENT_CALENDER_INFO_TEXT']);
        let configs = { ...configRes };

        if (res['access_token']) {
          window.localStorage.setItem('loginurl', environment.baseUrl)
          window.localStorage.setItem('id_token', res['id_token']);

          //get user details
          this.getUserDetails({ email: email }).subscribe({
            next: (details: any) => {

              try {
                this.BrowserLoggerObj = new BrowserLogger();
                this.RumLoggerObj = new RumLogger();
                this.RumLoggerObj$ = this.RumLoggerObj;
              } catch (error) {
                //do nothing
              }

              try {
                if (this.RumLoggerObj$) {
                  this.RumLoggerObj$.setCustomUser({
                    "displayName": details['name'],
                    "email": details['email'],
                    "appId": details['app_metadata'].merchAppId,
                    "oppId": details['app_metadata'].opportunityId,
                    "cardId": details['app_metadata'].cardId,
                    "name": details['name'],
                    "id": details['app_metadata'].opportunityId,
                    "platform": (window.localStorage.getItem('platform') || 'browser')
                  });


                  this.RumLoggerObj$.setCustomAction(JSON.stringify({
                    "displayName": details['name'],
                    "email": details['email'],
                    "appId": details['app_metadata'].merchAppId,
                    "oppId": details['app_metadata'].opportunityId,
                    "cardId": details['app_metadata'].cardId,
                    "name": details['name'],
                    "id": details['app_metadata'].opportunityId,
                    "platform": (window.localStorage.getItem('platform') || 'browser')
                  }), {
                    "displayName": details['name'],
                    "email": details['email'],
                    "appId": details['app_metadata'].merchAppId,
                    "oppId": details['app_metadata'].opportunityId,
                    "cardId": details['app_metadata'].cardId,
                    "name": details['name'],
                    "id": details['app_metadata'].opportunityId,
                    "platform": (window.localStorage.getItem('platform') || 'browser')
                  });


                }
              } catch (error) {
                //do nothing
              }
              /*sets user data to rum logs*/

              window.localStorage.setItem('oppId', details['app_metadata'].opportunityId)
              window.localStorage.setItem('displayName', details['name'])
              window.localStorage.setItem('login_ts', new Date().getTime().toString());
              window.localStorage.setItem('email', details['email'])
              window.localStorage.setItem('name', details['name'])
              window.localStorage.setItem('cardId', details['app_metadata'].cardId)
              window.localStorage.setItem('coverdashBusinessId', details['app_metadata'].coverdashBusinessId ?? '')
              window.localStorage.setItem('contId', details['app_metadata'].contId)
              window.localStorage.setItem('appId', details['app_metadata'].merchAppId)
              window.localStorage.setItem('logins_count', details['logins_count'] ? details['logins_count'].toString() : 'NA')
              window.localStorage.setItem('accountOwner', 'true');
              window.localStorage.setItem('Grace_Window_Days', configs['Grace_Window_Days']);
              window.localStorage.setItem('Post_Grace_Premium', configs['Post_Grace_Premium']);
              window.localStorage.setItem('US_HOLIDAYS', configs['US_HOLIDAYS']);
              if (details["app_metadata"].isOperator) {
                window.localStorage.setItem("userMode", "operator");
              } else {
                window.localStorage.setItem("userMode", "general");
              }
              //  let appName = environment.appName;

              window.localStorage.setItem("token", JSON.stringify(res));
              window.localStorage.setItem("appName", environment.appName);
              if (email && res['access_token'])
                this.fetchUserCache(email, res['access_token']);
              /*cache implementation*/
              this.handleNavigationAfterLogin(redirectURL, platformName);
            },
            error: (userDetailsError: any) => {
              this.handleError(userDetailsError);
            }
          });
        }


      },
      error: (configError: any) => {
        this.handleError(configError);
      }
    });
  }


  handleNavigationAfterLogin(redirectURL: string, platformName: string) {
    this.accountService.getAccountSummary().subscribe((res: any) => {
      try {
        res = JSON.parse(res);
        localStorage.setItem('legalName', res.RevAccountSummary.summary.legalName)
        let isCollection = res.RevAccountSummary.summary?.inCollection || 'false';

        if (isCollection === 'true') {
          this.router.navigate(['/dashboard/collection']);
        } else {
          let platformName = window.localStorage.getItem('platform') || 'browser';
          if (redirectURL && platformName !== 'Android' && platformName !== 'iOS') {
            if (redirectURL === '/pricing') {
              this.router.navigate(['/dashboard/prepay-cal']);
            } else {
              this.router.navigate([redirectURL]);
            }
          } else {
            if (localStorage.getItem('skipIntro') && localStorage.getItem('skipIntro') !== 'true' && localStorage.getItem('environment') !== 'web' && localStorage.getItem('environment')) {
              console.log(localStorage.getItem('skipIntro'));
              this.router.navigate(['/walkthrough']);
            } else {
              this.router.navigate(['/dashboard']);
            }
          }
        }
      } catch (error) {
        this.router.navigate(['/dashboard']);
      }
    }, (error: any) => {
      this.router.navigate(['/dashboard']);
    }, () => {
      // this.loggedIn = false;
    });
  }

  checkForMaintainanceMode() {
    const headers = { skip_interceptor: "true" };
    return this.http
      .get(`${environment.appUrl}assets/status.json`, {
        headers,
      });
  }

  checkAppSupportedVersions(deviceType: any) {

    let url = `${environment.baseUrl}v1/authentication/getAllSupportedAndroidVersions`;
    if (deviceType === 'iOS') {
      url = `${environment.baseUrl}v1/authentication/getAllSupportediOSVersions`;
    }

    const headers = { skip_interceptor: "true" };
    return this.http
      .get(url, {
        headers,
      });
  }


}
