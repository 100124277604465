<section id="login_page" style="height:100%" class="auth_page">
   <div class="row no-gutters">
      <div class="col left_maincol  text-white leftPanel">
         <div class="left_col">
            <a class="navbar-brand logohead" href="/login">
               <img src="../../../assets/images/logo/revenuedLogoNew.svg" width="200" height="40"
                  class="d-inline-block align-top" alt="">
            </a>
            <h1 class="title">{{landingPageDescription}}</h1>
            <!-- <p class="data">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, cupiditate?</p> -->
         </div>
         <footer>
            <nav class="navbar navbar-expand-lg px-0 ">
               <ul class="navbar-nav text-uppercase">
                  <li class="nav-item">
                     <a class="nav-link" href="https://www.revenued.com/about/" target="_blank">About
                        Revenued</a>
                  </li>
                  <li class="nav-item">
                     <a class="nav-link" href="https://www.revenued.com/contact/" target="_blank">Contact us</a>
                  </li>
                  <li class="nav-item">
                     <a class="nav-link" href="https://www.revenued.com/revenued-cardholder-agreement/"
                        target="_blank">Card User Agreement</a>
                  </li>
               </ul>
            </nav>
         </footer>
      </div>
      <div class="col d-flex justify-content-center right_maincol">
         <div class="right_col">
            <div class="right_col_head">
               <div class="d-flex text-center justify-content-start">
                  <h3 class="authPageTitle">Welcome to <span>Revenued</span></h3>
               </div>
            </div>
            <form [formGroup]="forgotPasswordForm" id="form" (ngSubmit)="forgotPassword()">
               <div>
                  <div class="p-2 formFieldInput">
                     <label class="" for="password" [class.field-focus]="isFieldFocussed">Enter Your Email Address
                        <span class="text-danger">*</span></label>
                     <div class="input-group">

                        <input formControlName="email" type="text" class="form-control" [readonly]="loader"
                           placeholder="Email Address" (focus)="forgotPwdOnFocus()" (focusout)="forgotPwdOnFocusOut()">

                        <div class="input-group-append">
                           <span class="input-group-text">
                              <i class="fa fa-envelope" aria-hidden="true"></i>
                           </span>
                        </div>
                     </div>
                     <div
                        *ngIf="(forgotPasswordForm.get('email')?.invalid && forgotPasswordForm.get('email')?.touched) || forgotPasswordForm.get('email')?.dirty">
                        <small class="text-danger" *ngIf="forgotPasswordForm.get('email')?.errors?.['required']">*Email
                           address
                           is required</small>
                        <small class="text-danger" *ngIf="forgotPasswordForm.get('email')?.errors?.['email']">Email
                           address
                           is invalid</small>
                     </div>
                  </div>
               </div>
               <div class="text-center login_btn">
                  <button type="submit" [disabled]="!forgotPasswordForm.valid"
                     class="btn btn-center login-button btn-responsive">
                     <ng-container *ngIf="!(loader && forgotPasswordForm.valid)">Reset
                        Password
                     </ng-container>
                     <div *ngIf="(loader && forgotPasswordForm.valid)" class="loader xs-loader mx-auto"></div>
                  </button>
               </div>
            </form>
            <p class="text-center p-2">
               <a [routerLink]="loader?null:'/login'" [class.disabled-link]="loader" class="for_pass">Login</a>
            </p>
         </div>
      </div>
   </div>
</section>