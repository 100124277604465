<div class="alert-box logOutModal">
    <div class="modal-header">
        <h4 class="modal-title m-0">Confirm</h4>
        <!-- <button type="button" class="close" aria-label="Close" (click)="_bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button> -->
        <figure class="m-0" (click)="_bsModalRef.hide()">
            <img src="../../../assets/new-dash/icons/closeIcon.svg" alt="Close Dialog">
        </figure>
    </div>
    <div class="modal-body">
        Are you sure, you want to logout?
    </div>
    <div class="modal-footer signout-confirmation-btn-group">
        <ion-button class="primary-general-btn" (click)="onConfirm()">
            Yes
        </ion-button>
        <ion-button class="primary-general-btn" (click)="onCancel()">
            No
        </ion-button>
    </div>
</div>