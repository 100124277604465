import { Component, OnInit, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/core/service/account.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: "root"
})
@Component({
    selector: 'app-conformation-modal',
    templateUrl: './conformation-modal.component.html',
    styleUrls: ['./conformation-modal.component.scss']
})
export class ConformationModalComponent implements OnInit {

    public onClose!: Subject<boolean>;

    constructor(public _bsModalRef: BsModalRef, private _accountService: AccountService, public router: Router,) { }

    ngOnInit(): void {
        this.onClose = new Subject();
    }

    public onConfirm(): void {
        this._accountService.logoutUser().subscribe((res) => {
            this.router.navigate(['/login']);
        });
        this.onClose.next(true);
        this._bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(false);
        this._bsModalRef.hide();
    }
}