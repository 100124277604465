<section class="create-pwd-page">
    <div class="create-pwd-wrapper">
        <div class="create-pwd card">
            <div class="section1">
                <div class="logoImgBox">
                    <img src="../../../assets/new-dash/logo/revenuedLogo_black.png" class="logoImg"
                        alt="Revenued Logo" />
                </div>
                <div class="formBox">
                    <ng-template *ngIf="isValidatingToken; else formContent">
                        <ngx-skeleton-loader count="1" appearance="line"
                            [theme]="{ height: '50px', width: '100%', margin: '10px 0' }"></ngx-skeleton-loader>
                    </ng-template>

                    <ng-template #formContent>
                        <ng-container *ngIf="createPasswordFlag === CreatePasswordStep.STEP1">
                            <h1 class="welcomeTitle">
                                Create Password?
                            </h1>
                            <p class="welcomePara">
                                Your new password must be unique and contain at least 8 characters with at least 1
                                special
                                character, 1 uppercase letter, 1 lowercase letter, and 1 number.
                            </p>
                            <form id="form" [formGroup]="createPasswordForm">
                                <!-- New Password -->
                                <mat-form-field appearance="outline" class="myMatFormFieldCustom w-100"
                                    subscriptSizing="dynamic">
                                    <input matInput placeholder="Enter New Password" formControlName="newPassword"
                                        [type]="fieldTextType ? 'text' : 'password'"
                                        aria-label="Please enter your new password. This is a required field.">
                                    <i matSuffix class="fa mouse-pointerz iconBoxSuffix"
                                        [ngClass]="{'fa-eye-slash': !fieldTextType, 'fa-eye': fieldTextType}"
                                        (click)="toggleFieldTextType()"></i>
                                    <mat-error *ngIf="newPassword?.touched && newPassword?.errors?.['required']">
                                        New password is required.
                                    </mat-error>
                                    <mat-error *ngIf="newPassword?.touched && newPassword?.errors?.['pattern']">
                                        Password must contain at least 8 characters, including 1 special character, 1
                                        uppercase letter, 1 lowercase letter, and 1 number.
                                    </mat-error>
                                </mat-form-field>

                                <!-- Confirm Password -->
                                <mat-form-field appearance="outline" class="myMatFormFieldCustom w-100"
                                    subscriptSizing="dynamic">
                                    <input matInput placeholder="Confirm Password" formControlName="confirmPassword"
                                        [type]="fieldTextType1 ? 'text' : 'password'"
                                        aria-label="Please enter your new password for confirmation. This is a required field.">
                                    <i matSuffix class="fa mouse-pointerz iconBoxSuffix"
                                        [ngClass]="{'fa-eye-slash': !fieldTextType1, 'fa-eye': fieldTextType1}"
                                        (click)="toggleFieldTextType1()"></i>
                                    <mat-error
                                        *ngIf="confirmPassword?.touched && confirmPassword?.errors?.['required']">
                                        Confirmation password is required.
                                    </mat-error>
                                    <mat-error
                                        *ngIf="createPasswordForm.errors?.['passwordMismatch'] && !(confirmPassword?.touched && confirmPassword?.errors?.['required'])  &&confirmPassword?.touched">
                                        Passwords do not match.
                                    </mat-error>
                                </mat-form-field>

                                <button class="primary-general-btn w-100"
                                    [disabled]="!(createPasswordForm.valid) || isSetPasswordProcessing"
                                    [class.disabled-link]="createPasswordForm.invalid || isSetPasswordProcessing"
                                    (click)="setPassword()" matRipple>
                                    <span *ngIf="isSetPasswordProcessing; else generalCondition">
                                        <app-loader></app-loader>
                                    </span>
                                    <ng-template #generalCondition>
                                        <span>Set Password</span>
                                    </ng-template>
                                </button>
                            </form>

                            <div class="bottom-action-links">
                                <a href="javascript:void(0);" routerLink="/login" class="link-items">
                                    <figure>
                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg"
                                            width="19.371" height="11.662" viewBox="0 0 19.371 11.662">
                                            <path id="Path_53" data-name="Path 53"
                                                d="M8.489,8.941a.8.8,0,0,1,0,1.135L4.811,13.754h15.82a.8.8,0,1,1,0,1.6H4.827l3.662,3.654a.8.8,0,1,1-1.127,1.127L2.276,15.049a.7.7,0,0,1,0-1.015L7.353,8.941a.8.8,0,0,1,1.135,0Z"
                                                transform="translate(-2.06 -8.704)" fill="#62cdf2" />
                                        </svg>
                                    </figure>
                                    Back To Sign-In
                                </a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="createPasswordFlag === CreatePasswordStep.STEP2">
                            <h1 class="welcomeTitle">
                                Oops! Link Expired
                            </h1>
                            <p class="welcomePara mt-4">
                                The link has expired. Please request a new link to reset your password.
                            </p>
                            <button id="open-toast" class="primary-general-btn w-100" [disabled]="isSentEmailProcessing"
                                matRipple (click)="presentToast()">
                                <!-- Request New Link -->
                                <span *ngIf="isSentEmailProcessing; else generalCondition">
                                    <app-loader></app-loader>
                                </span>
                                <ng-template #generalCondition>
                                    <span>Resend Link</span>
                                </ng-template>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="createPasswordFlag === CreatePasswordStep.STEP3">
                            <h1 class="welcomeTitle">
                                New Link Sent
                            </h1>
                            <p class="welcomePara mt-4">
                                Please continue from invitation.
                            </p>
                        </ng-container>
                    </ng-template>
                </div>
            </div>
            <div class="section2">
                <div class="inner-div">
                    <!-- <p class="content">
              A card that cares less about FICO and more about your business
            </p> -->
                    <figure class="m-0">
                        <img src="../../../assets/new-dash/login/createPwd.webp" alt="Forgot Password" />
                    </figure>
                </div>
            </div>
        </div>
        <div class="toastWrapper">
            <!-- <ion-button id="open-toast" expand="block">Open</ion-button> -->
            <!-- <ion-toast class="custom-toast" trigger="open-toast" positionAnchor="footer" message="
            A new link has been sent to your email address. Please check your email to reset your password."
                [duration]="5000"></ion-toast> -->
        </div>
        <footer class="footer--forgotPwd">
            <div class="footerMenu">
                <a href="https://www.revenued.com/about/" class="footerMenuLink" target="_blank">
                    About Revenued
                </a>
                <a href="https://www.revenued.com/contact/" class="footerMenuLink" target="_blank">
                    Contact Us
                </a>
                <a href="https://www.revenued.com/revenued-cardholder-agreement/" class="footerMenuLink"
                    target="_blank">
                    Card User Agreement
                </a>
            </div>
        </footer>
    </div>
    <!-- <div class="bgImage"></div> -->
</section>



























<!-- Old code : To be deleted later by Bijay -->
<section id="login_page" style="height:100%" class="pwd_page auth_page d-none">
    <div class="row no-gutters">
        <div class="col left_maincol  text-white leftPanel">
            <div class="left_col">

                <a class="navbar-brand logohead" href="/">
                    <!-- <img src="/assets/logo_white.png" width="35.3" height="40" class="d-inline-block align-top" alt="">
                    <span class="ml-3 ">Revenued</span> -->
                    <img src="/assets/images/logo/revenuedLogoNew.svg" width="200" height="40"
                        class="d-inline-block align-top" alt="">
                </a>

                <h1 class="title">{{landingPageDescription}}</h1>
                <!-- <p class="data">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, cupiditate?</p> -->
            </div>
            <footer>
                <nav class="navbar navbar-expand-lg px-0 ">
                    <ul class="navbar-nav text-uppercase">
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/about/" target="_blank">About
                                Revenued</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/contact/" target="_blank">Contact us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.revenued.com/revenued-cardholder-agreement/"
                                target="_blank">Card user agreement</a>
                        </li>
                    </ul>
                </nav>
            </footer>
        </div>
        <div class="col d-flex justify-content-center right_maincol">

            <div class="right_col">
                <div class="right_col_head">
                    <div class="d-flex text-center justify-content-start">
                        <h3 class="authPageTitle" *ngIf="typeofPasswordUpdate==='verifyEmail'">Create Password</h3>
                        <h3 class="authPageTitle" *ngIf="typeofPasswordUpdate==='passwordReset'">Reset Password</h3>
                    </div>
                </div>

                <form [formGroup]="createPasswordForm" id="form" (ngSubmit)="setPassword()">
                    <div>
                        <div class="p-2 formFieldInput">
                            <label class="" for="password" [class.field-focus]="isPwdFocussed">Password <span
                                    class="text-danger">*</span></label>

                            <div class="input-group">

                                <input formControlName="newPassword" [type]="fieldTextType ? 'text' : 'password'"
                                    [readonly]="loader" (focus)="pwdOnFocus()" (focusout)="pwdOnFocusOut()"
                                    placeholder="Enter Password" class="form-control pwd-input">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa mouse-pointerz" [ngClass]="{
                                          'fa-eye-slash': !fieldTextType,
                                          'fa-eye': fieldTextType
                                        }" (click)="toggleFieldTextType()"></i>
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="newPassword?.touched && newPassword?.errors" class="text-danger">*Password is
                                mandatory</div>
                            <div *ngIf="newPassword?.errors " class="text-danger">
                                <div>Password must contain at least 8 Alphanumeric Characters with at least 1 special, 1
                                    uppercase, 1 lower case, 1 numeric character</div>
                            </div>
                        </div>
                        <div class="p-2 formFieldInput">
                            <label class="" for="confirmpassword" (keyUp)="0"
                                [class.field-focus]="isConfirmPwdFocussed">Confirm
                                Password <span class="text-danger">*</span></label>


                            <div class="input-group">
                                <input type="password" formControlName="confirmPassword" [readonly]="loader"
                                    [type]="fieldTextType1 ? 'text' : 'password'" class="form-control pwd-input"
                                    placeholder="Enter Password" (focus)="confirmpwdOnFocus()"
                                    (focusout)="confirmpwdOnFocusOut()" (blur)="arePasswordsEqual()">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa mouse-pointerz" [ngClass]="{
                                          'fa-eye-slash': !fieldTextType1,
                                          'fa-eye': fieldTextType1
                                        }" (click)="toggleFieldTextType1()"></i>
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="confirmPassword?.touched && confirmPassword?.errors " class="text-danger">
                                *Confirm Password is mandatory</div>
                            <div *ngIf="confirmPassword?.errors " class="text-danger">
                                <div>Password must contain at least 8 Alphanumeric Characters with at least 1 special, 1
                                    uppercase, 1 lower case, 1 numeric character</div>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="showPasswordNotError" class="text-danger">
                            <h6>New and Confirm Password don't Match - Make sure both passwords match.</h6>
                        </div>
                    </div>
                    <div class="text-center login_btn ">
                        <button type="submit" [disabled]="!createPasswordForm.valid"
                            class="btn btn-center login-button btn-responsive">
                            <ng-container *ngIf="!(loader && createPasswordForm.valid)">Set Password</ng-container>
                            <div *ngIf="(loader && createPasswordForm.valid)" class="loader xs-loader mx-auto"></div>
                        </button>
                    </div>
                </form>


                <div class="d-sm d-xl-none text-center mobile_signup">
                    <p clas="text-center"><a [routerLink]="loader?null:'#'"
                            [class.disabled-link]="loader"><span>Login</span></a></p>
                </div>
            </div>
        </div>
    </div>
</section>