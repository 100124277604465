import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { App } from '@capacitor/app'; // Import App from @capacitor/app

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.css']
})
export class UpgradeComponent implements OnInit {

  platform = localStorage.getItem('platform');

  constructor(
    public alertController: AlertController
  ) { }

  ngOnInit(): void {}

  goToDownload() {
    let url = "https://www.revenued.com";
    if (localStorage.getItem('platform') === 'Android') {
      url = environment.playstoreAppUrl;
    } else {
      url = environment.appstoreAppUrl;
    }
    this.openWithSystemBrowser(url);
  }

  showExitConfirm() {
    this.alertController
      .create({
        header: '',
        message: 'Do you want to close the app?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay',
            role: 'cancel',
            handler: () => {
              console.log('Application exit prevented!');
            },
          },
          {
            text: 'Exit',
            handler: () => {
              App.exitApp(); // Correct usage of App.exitApp() in Capacitor
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  // Open URL using the system browser with Capacitor's Browser plugin
  public async openWithSystemBrowser(url: string) {
    try {
      await Browser.open({ url: url });
    } catch (error) {
      console.error("Error opening browser: ", error);
    }
  }
}
