import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth-layout/login/login.component';
import { ForgotPwdComponent } from './auth-layout/forgot-pwd/forgot-pwd.component';
import { LoginWithOtpComponent } from './auth-layout/login-with-otp/login-with-otp.component';
import { ErrorspageComponent } from './errors-page/errorspage/errorspage.component';
import { NoInternetComponent } from './no-internet/no-internet.component';
import { MaintainanceComponent } from './maintainance/maintainance.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { DisabledComponent } from './disabled/disabled.component';
import { PricingCalComponent } from './shared/pricing-cal/pricing-cal.component';
import { NotificationsComponent } from './dashboard/inner-pages/notifications/notifications.component';
import { TabsPage } from './tabs/tabs.page';
import { TransactionhistoryIframeComponent } from './dashboard/transactionhistoryIframe/transactionhistory.component';
import { TransactionsModalComponent } from './dashboard/transactionhistoryIframe/components/transactions-modal/transactions-modal.component';
import { PaymentsModalComponent } from './dashboard/transactionhistoryIframe/components/payments-modal/payments-modal.component';
// import { VerifyEmailTokenResolverService } from './auth-layout/auth/verifyemailtoken.resolver';
import { CreatepasswordComponent } from './shared/createpassword/createpassword.component';
import { AuthGuard } from './auth-layout/auth/auth.guard';
import { VerifyLoginOTPComponent } from './auth-layout/verify-login-otp/verify-login-otp.component';
// import { VerifyIdentityComponent } from './auth-layout/verify-identity/verify-identity.component';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  // },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    //  loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) 
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login - Revenued' }

  },
  {
    path: 'passwordReset', component: CreatepasswordComponent,
    //  resolve: {
    //   verifyEmail: VerifyEmailTokenResolverService
    // }
  },
  { path: 'getTransactionHistory/:oppId/:appname/:token', component: TransactionhistoryIframeComponent },
  { path: 'getTransactionHistory/:oppId/:appname/:token/:tab', component: TransactionhistoryIframeComponent },
  { path: 'getTransactionHistory/:oppId/:appname/:token/:tab/:id', component: TransactionhistoryIframeComponent },
  { path: 'getTransactionHistory/:oppId/:appname/:token/:tab/:id/:random', component: TransactionhistoryIframeComponent },
  { path: 'transaction-details/:oppId/:appname/:token/:tid', component: TransactionsModalComponent },
  { path: 'payment-details/:oppId/:appname/:token/:pid', component: PaymentsModalComponent },
  {
    path: 'forgot-password',
    component: ForgotPwdComponent,
    data: { title: 'Forgot Password - Revenued' }
  },
  // {
  //   path: 'verify-identity',
  //   component: VerifyIdentityComponent,
  //   data: { title: 'Verify Identity - Revenued' }
  // },
  {
    path: 'no-internet',
    component: NoInternetComponent
  },
  {
    path: 'login-with-otp',
    component: LoginWithOtpComponent,
    data: { title: 'Login with OTP - Revenued' }
  },
  {
    path: 'verify-code',
    component: VerifyLoginOTPComponent,
    data: { title: 'Verify Code - Revenued' }
  },
  {
    path: "maintainance",
    component: MaintainanceComponent,
    data: { title: 'Maintainance - Revenued' }
  },
  {
    path: "upgrade",
    component: UpgradeComponent,
    data: { title: 'Upgrade - Revenued' }
  },
  {
    path: "disabled",
    component: DisabledComponent,
    data: { title: 'Disabled - App' }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: "error", component: ErrorspageComponent,
    data: { title: 'Error - Revenued' }
  },
  {
    path: "error/:code", component: ErrorspageComponent,
    data: { title: 'Error - Revenued' }
  },

  {
    path: "pricing",
    component: PricingCalComponent,
    data: { title: 'Understand Your Pricing - Revenued' }
  },
  {
    path: "notifications",
    component: NotificationsComponent,
    canActivate: [AuthGuard],
    data: { title: 'Notifications - Revenued' }
  },
  {
    path: 'collection',
    //   component: RequestPrepayComponent,
    loadChildren: () => import('./dashboard/inner-pages/request-collection/request-collection.module').then(m => m.RequestCollectionModule),
    canActivate: [AuthGuard]
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
