import { Component, HostListener, Inject, OnInit, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-detail-modal',
  templateUrl: './notification-detail-modal.component.html',
  styleUrls: ['./notification-detail-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationDetailModalComponent implements OnInit {
  @ViewChildren('mainContent') mainContent: any;

  title!: string;
  subtitleContent!: string;
  dateTime!: string;

  constructor(
    public dialogRef: MatDialogRef<NotificationDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log('data', this.data);
    this.title = this.data.title;
    this.subtitleContent = this.data.subtitleContent;
    this.dateTime = this.data.dateTime;
  }

  ngAfterViewInit(): void {
    if (window.innerWidth < 768) {
      const scrollingContainer = document.querySelector('.notificationDetailDialog .mat-mdc-dialog-surface') as HTMLElement;
      scrollingContainer.addEventListener('scroll', this.onModalScroll.bind(this));
    }
  }

  isStickyHeaderVisible: boolean = false;
  onModalScroll(event: any): void {
    if (event.target.scrollTop >= 20) {
      this.isStickyHeaderVisible = true;
      console.log("scrollisStickyHeaderVisible: " + this.isStickyHeaderVisible);
      return;
    }
    else {
      this.isStickyHeaderVisible = false;
    }
    console.log("scrolling: " + event.target.scrollTop);
  }

  closeNotificationDialog() {
    this.dialogRef.close();
  }
}
