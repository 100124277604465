import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/service/authentication.service';

@Component({
  selector: 'app-forgot-pwd',
  templateUrl: './forgot-pwd.component.html',
  styleUrls: ['./forgot-pwd.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPwdComponent implements OnInit {

  // email = new FormControl('', [Validators.required, Validators.email]);
  initiateResetPwd: boolean = false;
  fieldTextType: boolean = false;
  successMessage!: string;
  errorMessage!: string;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  resetPwdFormGroup!: FormGroup; // Initialize the form property

  constructor(
    private formBuilder: FormBuilder, private authService: AuthenticationService,
    private router: Router,
  ) { }



  ngOnInit() {
    this.resetPwdFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
      // Your form controls and validators here
    });
  }
  getEmailErrorMessage() {
    // console.log("I am here");
    if (this.resetPwdFormGroup.get('email')?.hasError('required')) {
      return 'This is a required field.';
    }

    return this.resetPwdFormGroup.get('email')?.hasError('email') ? 'Please enter your email address.' : '';
  }

  resetPwdProcessInitiate() {
    this.successMessage = '';
    this.errorMessage = '';
    const emailValue = this.resetPwdFormGroup.get('email')?.value?.toLowerCase()?.trim() || '';
    this.initiateResetPwd = true;
    this.authService.forgotPwd({ email: emailValue }).subscribe({
      next: (response: any) => {
        console.log('response:', response);
        if (response.success) {
          this.successMessage = 'Password reset sent via email.';
        this.resetPwdFormGroup.get('email')?.reset('', { emitEvent: false });
             
          // this.router.navigate(['/login']);
        }
      }, error: (error: any) => {
        console.log('error:', error);
        this.initiateResetPwd = false;
        if (error.status === 400) {
          this.errorMessage = error.error?.message || 'Provided email address is not valid.';
        } else {
          this.errorMessage = 'Something went wrong. Please try again later.';
        }
      },
      complete: () => {
        this.initiateResetPwd = false;
      }
    }
    );
  }

}
