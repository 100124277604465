import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { IonButton, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
// import { LoginFormErrorModalComponent } from '../../shared/login-form-error-modal/login-form-error-modal.component';
import { AccountService } from 'src/app/core/service/account.service';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


enum CreatePasswordStep {
  STEP1 = 'passwordReset',
  STEP2 = 'linkExpired',
  STEP3 = 'emailSent'
}

// Custom validator to check if passwords match
function passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
  const newPassword = control.get('newPassword');
  const confirmPassword = control.get('confirmPassword');
  if (newPassword && confirmPassword && newPassword.value !== confirmPassword.value) {
    return { passwordMismatch: true };
  }
  return null;
}

@Component({
  selector: 'app-createpassword',
  templateUrl: './createpassword.component.html',
  styleUrls: ['./createpassword.component.scss']
})
export class CreatepasswordComponent implements OnInit {
  createPasswordFlag: CreatePasswordStep = CreatePasswordStep.STEP1;
  passwordSetFlag: boolean = false;
  createPasswordForm: FormGroup;
  email!: string;
  passwordToken!: string;
  showPasswordNotError!: boolean;
  typeofPasswordUpdate = "";
  isPwdFocussed!: boolean;
  modalRef!: BsModalRef;
  fieldTextType!: boolean;
  CreatePasswordStep = CreatePasswordStep;
  isSentEmailProcessing = false;
  isSetPasswordProcessing = false;
  isValidatingToken = true;

  
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  fieldTextType1!: boolean;
  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }
  constructor(
    public accountService: AccountService,
    public authService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private toastController: ToastController
  ) {
    this.createPasswordForm = this.fb.group({
      email: ['', [Validators.required]],
      newPassword: ['', {
        updateOn: 'blur',
        validators: [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&].{7,}')
        ]
      }],
      passwordToken: [],
      confirmPassword: ['', {
        updateOn: 'blur',
        validators: [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&].{7,}')
        ]
      }]
    }, { validators: passwordMatchValidator });
  }
  isConfirmPwdFocussed = false;
  confirmpwdOnFocus() {
    this.isConfirmPwdFocussed = true;
  }
  confirmpwdOnFocusOut() {
    this.isConfirmPwdFocussed = false;
  }
  pwdOnFocus() {
    this.isPwdFocussed = true;
  }
  pwdOnFocusOut() {
    this.isPwdFocussed = false;
  }
  landingPageDescription: any;
  ngOnInit() {
    console.log(this.createPasswordFlag,"createPasswordFlag");
    
    this.activatedRoute.queryParams.subscribe(params => {
      this.email = params['email'];
      this.passwordToken = params['passwordToken'] || params['token'];
    });
    this.authService.checkPasswordToken({ "email": this.email, "passwordToken": this.passwordToken }).subscribe(
      (res) => {
        this.isValidatingToken = false;
      },
      (err) => {
        this.isValidatingToken = false;
        let {error} = err.error

        this.createPasswordFlag = CreatePasswordStep.STEP2;
        // this.showToaster(error)
        // if (error === "Invalid Token") {
        //   // this.passwordSetFlag = true;
        // }
      }
    );
    this.landingPageDescription = localStorage.getItem('LANDING_PAGE_DESCRIPTION') || 'Revenued offers a FlexLine + Card built for all businesses';
    // console.log(this.activatedRoute.snapshot);
    this.typeofPasswordUpdate = this.activatedRoute?.routeConfig?.path ?? '';
    // console.log("this.activatedRoute.pathFromRoot",this.activatedRoute.pathFromRoot);
    this.activatedRoute.url.subscribe((res) => {
      console.log("url", res)
    });
    this.activatedRoute.queryParams.subscribe(({ email, token }) => {
      // this.authService.checkPasswordToken({"email":email,"passwordToken":token})
      // .subscribe((res)=>{
      //   alert("HI")
      //   if(res['error']){

      //   }
      // },()=>{alert("Your Link Validity Expired. Please contact Amin");
      // this.router.navigate([`/`]);})
      this.createPasswordForm.patchValue({ email })
      this.createPasswordForm.patchValue({ passwordToken: token })
    })
    // this.authService.getConfigs();
  }
  get newPassword() {
    return this.createPasswordForm.get('newPassword')
  }
  get confirmPassword() {
    return this.createPasswordForm.get('confirmPassword')
  }
  loader = false;
  setPassword() {
    this.isSetPasswordProcessing = true;
    // if (!this.createPasswordForm.touched) {
    //   // alert("Please fill the form to update the Password");
    //   // const initialState = {
    //   //   title: '',
    //   //   message: "Required field(s) missing"
    //   // };
    //   // this.openModal(initialState);
    //   this.createPasswordForm.markAllAsTouched();
    // }
    // if (this.arePasswordsEqual() && this.createPasswordForm.valid) {
      // console.log(this.createPasswordForm)
      const payload = {
        email: this.email,
        passwordToken: this.passwordToken,
        newPassword: this.createPasswordForm.value.newPassword
      }
      this.loader = true;
      this.authService.updatePwd(payload).subscribe(() => {
        this.loader = false;
        this.showToaster('Password updated successfully')
        this.router.navigate(['/login']);
        this.isSetPasswordProcessing = false;
        // const initialState = {
        //   title: 'Success',
        //   message: "Password Created."
        // };
        // this.createCase("Closed", "Website Support", "Password created successfully", `Password Creation`, () => {});
        // this.openModal(initialState);
        // window.location.reload();
      }, (err) => {
        this.isSetPasswordProcessing = false;
        this.loader = false;
        if (err) {
          console.log("Error", err)
        }
        if(err.error.error === 'Token Expired'){
          this.createPasswordFlag = CreatePasswordStep.STEP2;
        }
      });
    // }
  }
  arePasswordsEqual() {
    console.log(this.confirmPassword?.value, this.newPassword?.value)
    if (this.confirmPassword?.value !== this.newPassword?.value) {
      // alert("New Password and Confirm Password are Not Equal");
      const initialState = {
        title: 'Error',
        message: "Password confirmation does not match"
      };
      this.openModal(initialState);
      return false;
    }
    return true;
  }
  openModal(initialState: any) {
    if (this.modalRef) {
      this.modalRef.hide();
    }

    // this.modalRef = this.modalService.show(LoginFormErrorModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }
  createCase(status: string, reason: string, description: string, subject: string, cb: Function) {
    this.accountService.createCase({ accId: localStorage.getItem("acctId"), subject, status, reason, description, submitter: window.localStorage.getItem('email') }).subscribe((res) => {
      console.log(res)
      if (cb) {
        cb(res);
      }
    }, (er) => console.error(er));
  }
  // setPassword(){
  //   console.log(this.createPasswordForm)
  // this.authService.updatePwd(this.createPasswordForm.value)
  // .subscribe(()=>{
  //   alert("Password Updation Successfull")
  //   this.router.navigate([`/`])
  // },(response)=>{
  //   alert(" Error Occurred. Please contact Administrator");
  //   //console.log(response)
  // })
  // }

  async showToaster(message: string){
    const toast = await this.toastController.create({
      message,
      duration: 5000,
      cssClass: 'custom-toast',
      position: 'bottom',
    });
    await toast.present();
  }

  async presentToast() {
    this.isSentEmailProcessing = true;
   this.authService.forgotPwd({email: this.email}).subscribe(async(res)=>{
    console.log("RESSSS::", res);
    this.createPasswordFlag = CreatePasswordStep.STEP3;
    this.showToaster('A new link has been sent to your email address. Please check your email to reset your password.')
    this.isSentEmailProcessing = false;
   })
  }

  async updatePwd(){
    this.isSetPasswordProcessing = true;
    const payload = {
      email: this.email,
      passwordToken: this.passwordToken,
      newPassword: this.createPasswordForm.value.newPassword
    }
    this.authService.updatePwd(payload).subscribe((res)=>{
      this.isSetPasswordProcessing = false;
    });
  }
}
