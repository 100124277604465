import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// Additional Components
import { LoginComponent } from './auth-layout/login/login.component';
// import { VerifyIdentityComponent } from './auth-layout/verify-identity/verify-identity.component';
// import { OtherVerificationMethodsComponent } from './auth-layout/verify-identity/other-verification-methods/other-verification-methods.component';
import { LoaderComponent } from './shared/loader/loader.component';

// Additional Modules
import { MaterialModule } from './shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPwdComponent } from './auth-layout/forgot-pwd/forgot-pwd.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedModuleModule } from './shared/shared-module.module';
import { CurrencyPipe } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpLoadingInterceptor } from './errors/http-loading.interceptor';
import { CreatepasswordComponent } from './shared/createpassword/createpassword.component';
import { NoInternetComponent } from './no-internet/no-internet.component';
import { ForgotpasswordComponent } from './shared/forgotpassword/forgotpassword.component';
import { MFAOtpLoginComponent } from './shared/mfa-otplogin/mfa-otplogin.component';
import { OtpLoginComponent } from './shared/otplogin/otplogin.component';
import { PricingCalComponent } from './shared/pricing-cal/pricing-cal.component';
import { HeaderRightComponent } from './shared/header-right/header-right.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ToastrModule } from "ngx-toastr";
import { ConformationModalComponent } from './shared/conformation-modal/conformation-modal.component';
import { MaintainanceComponent } from './maintainance/maintainance.component';
import { IonicStorageModule } from '@ionic/storage-angular'; // Import Ionic Storage
import { NotificationDetailModalComponent } from './dashboard/common-components/notification-bar/notification-detail-modal/notification-detail-modal.component';
import { FooterGeneralComponent } from './dashboard/common-components/footer-general/footer-general.component';
import { LogoutModalComponent } from './shared/logout-modal/logout-modal.component';
import { VerifyLoginOTPComponent } from './auth-layout/verify-login-otp/verify-login-otp.component';
import { CheckMfaStatusComponent } from './auth-layout/verify-login-otp/check-mfa-status/check-mfa-status.component';
import { NumbersOnlyDirective } from './utils/numbers-only.directive';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    // VerifyIdentityComponent,
    // OtherVerificationMethodsComponent,
    LoaderComponent,
    ForgotPwdComponent,
    MaintainanceComponent,
    CreatepasswordComponent,
    ForgotpasswordComponent,
    NoInternetComponent,
    MFAOtpLoginComponent,
    OtpLoginComponent,
    HeaderRightComponent,
    PricingCalComponent,
    ConformationModalComponent,
    NotificationDetailModalComponent,
    FooterGeneralComponent,
    LogoutModalComponent,
    VerifyLoginOTPComponent,
    CheckMfaStatusComponent,
  ],
  imports: [
    NumbersOnlyDirective,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    ModalModule.forRoot(),
    IonicStorageModule.forRoot(), // Add this line for storage initialization
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModuleModule,
    HttpClientModule,
    NzDropDownModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      progressAnimation: 'increasing',
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      progressBar: true,
    })
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpLoadingInterceptor,
    multi: true,
  },
    CurrencyPipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
