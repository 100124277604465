import {
  Component,
  OnInit,
  Input
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { TransactionStatus } from "../../../../core/model/transaction.status";
import { TransactionsModalComponent } from "../transactions-modal/transactions-modal.component";
import { AccountService } from "src/app/core/service/account.service";
@Component({
  selector: "app-payments-modal",
  templateUrl: "./payments-modal.component.html",
  styleUrls: ["./payments-modal.component.scss"],
})
export class PaymentsModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    
    private modalService: NgbModal
  ) { }
  @Input() public oppId: any;
  @Input() public token:any;
  @Input() public appname:any;
  @Input() public pid:any;
  indicator: boolean = true;
  transactionStatus = TransactionStatus;
  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {
      this.pymtDetails = {};
      this.isTransactionsLoaded = false;
      this.getPaymentDetails(this.pid);
    });
  }

  isTransactionsLoaded: boolean = false;
  pymtDetails: any = {};
  isTransactionDetailsLoaded: boolean = true;
  getPaymentDetails(id:any) {
    this.pymtDetails = {};
    this.isTransactionDetailsLoaded = false;
    this.isTransactionsLoaded = true;
    this.accountService.getPaymentDetails(id,this.oppId,this.token,this.appname).subscribe(
      (data:any) => {
        this.pymtDetails = JSON.parse(data);


        if (this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail) {
          this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail.sort(function (a: any, b: any) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.sysDate).getTime() - new Date(a.sysDate).getTime();
          });
        }

        this.isTransactionDetailsLoaded = true;
        this.isTransactionsLoaded = true;
      },
      (error:any) => {
        this.isTransactionDetailsLoaded = true;
        this.isTransactionsLoaded = true;
      }
    );
  }



  openPaymentModal(pid:any,trxn:any) {
    const modalRef = this.modalService.open(PaymentsModalComponent,{ size: 'lg' });
    modalRef.componentInstance.oppId =this.oppId;
    modalRef.componentInstance.token =this.token;
    modalRef.componentInstance.appname =this.appname;
    modalRef.componentInstance.pid =pid;
  }
  searchItem:any=null;

  openTransactionModal(tid:any,trxn:any) {
    const modalRef = this.modalService.open(TransactionsModalComponent,{ size: 'lg' });
    modalRef.componentInstance.oppId =this.oppId;
    modalRef.componentInstance.token =this.token;
    modalRef.componentInstance.appname =this.appname;
    modalRef.componentInstance.tid =tid;
  }
}
