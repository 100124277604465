import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogoutModalComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { }
  dismiss() {
    this.modalCtrl.dismiss();
  }

  onWillDismiss() {
    console.log("Dissmissing...");
  }

  logout() {
    console.log("Logging out...");
    this.modalCtrl.dismiss(); // Close modal before logging out
    // Add your logout logic here
  }

  ngOnInit() { }



}
