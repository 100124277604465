<ion-content class="">
  <div class="modal-body text-center">
    <figure>
      <img src="../../assets/new-dash/timeoutIcon.svg" alt="Timeout Icon">
    </figure>
    <div class="modal-title-container">
      <h4 id="dialog-child-name" class="modal-title">
        Session Timeout!
      </h4>
      <h4 class="modal-title">
        Are You Still With Us?
      </h4>
    </div>
    <p class="note">
      Privacy is essential and you've been gone a while. We will log you out shortly unless you
      confirm
      you're
      still with us.
    </p>
    <div class="actionBtns">
      <ion-button type="button" class="primary-general-btn mr-3 whiteBtn" (click)="logout()">Logout</ion-button>
      <ion-button class="primary-general-btn mr-3 " (click)="dismiss()">Resume</ion-button>
    </div>
  </div>
</ion-content>