<section class="reset-pwd-page">
  <div class="reset-pwd-wrapper">
    <div class="reset-pwd card">
      <div class="section1">
        <div class="logoImgBox">
          <img src="../../../assets/new-dash/logo/revenuedLogo_black.png" class="logoImg" alt="Revenued Logo" />
        </div>
        <div class="formBox">
          <h1 class="welcomeTitle">
            Forgot Password?
          </h1>
          <p class="welcomePara">
            Enter the email address associated with your account
          </p>
          <form class="" [formGroup]="resetPwdFormGroup">
            <mat-form-field appearance="outline" class="myMatFormField" [class.mat-form-field-invalid-nonempty]="
            resetPwdFormGroup.get('email')?.invalid &&
            (resetPwdFormGroup.get('email')?.touched || resetPwdFormGroup.get('email')?.dirty)">
              <!-- Add 'invalid' to the class of mat-form-field for invalid inputs -->
              <!-- <mat-label>Enter Email Address</mat-label> -->
              <img matPrefix src="../../../assets/new-dash/login/emailIcon.png" class="iconBox" alt="Email icon" />
              <input matInput placeholder="Email Address" formControlName="email" required name="Email Address"
                aria-label="Please enter your email address. This is a required field.">
            <mat-error
              *ngIf="resetPwdFormGroup.get('email')?.invalid && (resetPwdFormGroup.get('email')?.touched || resetPwdFormGroup.get('email')?.dirty)">
              {{ getEmailErrorMessage() }}
            </mat-error>

              <!-- <mat-hint class="help-text">Please enter your email address</mat-hint> -->
            </mat-form-field>


            <!-- <input type="text" /> -->
            <button class="primary-general-btn w-100" [disabled]="!(resetPwdFormGroup.valid)"
              [class.disabled-link]="resetPwdFormGroup.invalid" (click)="resetPwdProcessInitiate()" matRipple>
              <span *ngIf="initiateResetPwd; else generalCondition">
                <app-loader></app-loader>
              </span>
              <ng-template #generalCondition>
                <span>Reset</span>
              </ng-template>
            </button>

            <div *ngIf="successMessage" class="success-message">
              {{ successMessage }}
            </div>
            <div *ngIf="errorMessage" class="error-message">
              {{ errorMessage }}
            </div>
          </form>
          <div class="bottom-action-links">
            <a href="javascript:void(0);" routerLink="/login" class="link-items">
              <figure>
                <!-- <img src="../../../assets/new-dash/login/backArrow.svg" alt="Back to Sign-In icon" /> -->
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="19.371" height="11.662"
                  viewBox="0 0 19.371 11.662">
                  <path id="Path_53" data-name="Path 53"
                    d="M8.489,8.941a.8.8,0,0,1,0,1.135L4.811,13.754h15.82a.8.8,0,1,1,0,1.6H4.827l3.662,3.654a.8.8,0,1,1-1.127,1.127L2.276,15.049a.7.7,0,0,1,0-1.015L7.353,8.941a.8.8,0,0,1,1.135,0Z"
                    transform="translate(-2.06 -8.704)" fill="#62cdf2" />
                </svg>

              </figure>
              Back To Sign-In
            </a>
          </div>
        </div>
      </div>
      <div class="section2">
        <div class="inner-div">
          <!-- <p class="content">
            A card that cares less about FICO and more about your business
          </p> -->
          <figure class="m-0">
            <img src="../../../assets/new-dash/login/createPwd.webp" alt="Forgot Password" />
          </figure>
        </div>
      </div>
    </div>
    <footer class="footer--forgotPwd">
      <div class="footerMenu">
        <a href="https://www.revenued.com/about/" class="footerMenuLink" target="_blank">
          About Revenued
        </a>
        <a href="https://www.revenued.com/contact/" class="footerMenuLink" target="_blank">
          Contact Us
        </a>
        <a href="https://www.revenued.com/revenued-cardholder-agreement/" class="footerMenuLink" target="_blank">
          Card User Agreement
        </a>
      </div>
    </footer>
  </div>
  <!-- <div class="bgImage"></div> -->
</section>