import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";

import { AccountService } from "../core/service/account.service";
import { Observable, throwError } from "rxjs";
import { catchError, retry, finalize } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  environment,
  httpInterceptorEnvironment,
} from "../../environments/environment";

import { Router } from "@angular/router";


//errors caseHandler
import { CaseHandler } from "./case-handler";
import { AuthenticationService } from "../core/service/authentication.service";
import { ToastrService } from "ngx-toastr";
import { DataDogLoggerService } from "../core/datadog-logger.service";

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
  caseHandler = new CaseHandler();
  constructor(
    private accountService: AccountService,
    //  private loadingDialogService: LoadingDialogService,
    private router: Router,
    private logger: DataDogLoggerService,
    private toastr: ToastrService,
    private auth: AuthenticationService
  ) {


  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

 // Clone the request and add the new headers
 const headers: any = {
  appName: environment.appName,
  REVENUED_MIDDLEWARE_TOKEN: environment.REVENUED_MIDDLEWARE_TOKEN
};

// // Add the Authorization header only if the token exists
// const token = JSON.parse(localStorage.getItem("token")||"");
// if (token?.access_token) {
//   headers.Authorization = `Bearer ${token?.access_token}`;
// }

 const modifiedRequest = request.clone({ setHeaders: headers });
    //global interceptor error handler
      //no redirect on 401
      let noRedirect = ['verify2FAChallenge','clientAccessToken']
    return next.handle(modifiedRequest).pipe(
      retry(httpInterceptorEnvironment.errorHandlerRetryAttemt),
      catchError((error) => {
        if (error.status === 401 && !noRedirect.some(url => error.url?.includes(url))) {
          window.localStorage.clear();
          this.toastr.warning("Unauthorized!");
          this.router.navigate(['/login']);
        }
        const ENABLE_GLOBAL_HTTP_INTERCEPTOR = window.localStorage.getItem('ENABLE_GLOBAL_HTTP_INTERCEPTOR');
        //get errorType & textMessage for logger
        let result: any = this.caseHandler.getErrors(error);
        console.log(error)
        //the global error handler runs if environments varaibles wishes not to bypass
        if (ENABLE_GLOBAL_HTTP_INTERCEPTOR === "true") {

          //here we are parsing the request headers to see if the skip_interceptor is present or not
          if (!request.headers.get("skip_interceptor")) {
            //logging the error level and message into dataDog
             this.logger.logDataDogEvent("error", result.errorType + " :  " + result.textMessage, 'httpInterceptor')
               .subscribe(() => { });
            // logging the error type and title into logDNA
            this.accountService
              .logEvent({
                logLevel: "error",
                message: {
                  title: result.errorType + " :  " + result.textMessage,
                  oppId: localStorage.getItem("oppId"),
                  cardId: localStorage.getItem("cardId"),
                  drawAmount: 0,
                },
              })
              .subscribe(() => { });
            // redirect to error page with relavant error code to display

            if (error.status === 401) {
              this.toastr.warning("Session expired, please login again!");
              this.router.navigate(["/login"]).then((res) => {
                localStorage.clear();
              });
            } else if (error.hasOwnProperty('status')) {
              this.router.navigate(["/error", result?.status ? result?.status : '']);
            } else {
              this.router.navigate(["/error"]);
            }
          } else {
            console.log("AY AY CAPTAIN! SKIPPING INTERCEPTOR. - BY HEADER");
          }

          if (!request.headers.get("skip_interceptor_rum_logs")) {
            try {
              //create rum action to make it searchable in sessions&replay
              this.auth.RumLoggerObj$.setCustomAction('Error - ' + result.errorType + " :  " + result.textMessage + " " + 'httpInterceptor', {
                "url": request?.url,
                "req": request,
                "res": error,
                "log": result
              });

            } catch (error) {
              //do nothing
            }
          }
        } else {
          console.log("AY AY CAPTAIN! SKIPPING INTERCEPTOR. - BY ENV VARIABLE");
        }


        return throwError(error);
      }),
      finalize(() => {
        //   this.loadingDialogService.hideDialog();
      })
    ) as Observable<HttpEvent<any>>;
  }



}
