<section style="padding-bottom: 14px;">
    <div class="container" *ngIf="!refreshRequired">
        <div class="card1  d-none d-md-block d-lg-block d-xl-block">
            <div class="navbar navbar-expand-lg mt-3 mob-date">
                <h3 class="navbar-brand mr-auto font-weight-bold text-uppercase">
                    Transactions
                </h3>
            </div>
            <div class="paymentsTable table-responsive d-none d-md-block d-lg-block d-xl-block tabel-padding">
             
                <ng-container *ngIf="this.currentTransactions.length">
                    <div class="form-group m-3 tabel-padding" >
                        <div class="icon-addon addon-lg">
                            <label for="search" class="fa fa-search" rel="tooltip" title="Search..." *ngIf="!searchItem"></label>
                            <input type="text" id="search" placeholder="Search..." class="form-control search-box"  [(ngModel)]="searchItem">
                            <label class="fa fa-close mouse-pointerz"  (click)="searchItem=null" *ngIf="searchItem"></label>
                        </div>
                       
                    </div>
                </ng-container>
             
                <nz-table nzShowSizeChanger [nzData]="currentTransactions" [nzFrontPagination]="false"
                    [nzShowSizeChanger]="false" [nzLoading]="!isTransactionsLoaded" [nzTotal]="totalRowCount"
                    [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" (nzQueryParams)="onQueryParamsChange($event)"
                    class="outerTable">
                    <thead class="outerTableThead">
                        <tr class="outerTableRow">
                            <th nzWidth="60px"></th>
                            <th nzColumnKey="recDate" [nzSortOrder]="'descend'" [nzSortFn]="true"
                                [nzSortPriority]="true">Date</th>
                            <th nzColumnKey="id" [nzFilterFn]="true" [nzSortPriority]="true">Transaction ID</th>
                            <th nzColumnKey="name" [nzFilterFn]="true" [nzSortPriority]="true">Description</th>
                            <th nzColumnKey="status" [nzSortFn]="true" [nzSortPriority]="true" [nzFilterFn]="true">
                                Status</th>
                            <th nzColumnKey="amount" [nzSortFn]="true" [nzSortPriority]="true">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let transaction of currentTransactions">
                            <tr [ngClass]="expandSet.has(transaction.trxn.id) ? 'active': ''">
                                <td class="expander" [nzExpand]="expandSet.has(transaction.trxn.id)"
                                    id="expand-{{transaction.trxn.id}}"
                                    (click)="onExpandChange(transaction.trxn.id, null)">
                                </td>
                                <td>{{transaction.trxn.recDate | date}}</td>
                                <!-- (click)="showTransactionModal(transaction.trxn.id,transaction.trxn)" -->
                                <td><a (click)="openTransactionModal(transaction.trxn.id,transaction.trxn)"
                                        class="mouse-pointerz">{{transaction.trxn.id}}</a></td>
                                <td>{{transaction.trxn['cpty'].name}}</td>
                                <td>{{transactionStatus[transaction.trxn.status]}}</td>
                                <td class="">{{

                                    this.accountService.getFormattedAmount(transaction.trxn.amount)
                                    }}</td>
                            </tr>
                            <tr [nzExpand]="expandSet.has(transaction.trxn.id)" class="expandedRow">
                                <nz-table #innerTable [nzData]="transaction.trxn.cpty" nzSize="middle"
                                    [nzShowPagination]="false" class="innerTable">
                                    <thead>
                                        <tr class="innerTableRow">
                                            <th scope="col">Date</th>
                                            <th scope="col" style="width: 200px;">Payment ID</th>
                                            <th scope="col">Status</th>
                                            <!-- <th scope="col" class="text-right">Payment Amount</th> -->
                                            <th scope="col" class="text-right">Payment Allocation</th>
                                            <th scope="col" class="text-right">Discount Savings</th>
                                            <th scope="col" class="text-right">Starting Bal to Done</th>
                                            <th scope="col" class="text-right">Ending Bal to Done</th>
                                        </tr>
                                    </thead>
                    <tbody *ngIf="this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail">
                        <tr *ngFor="let item of this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail"
                            class="innerTableRow {{!this.accountService.checkIsVisible(item)?'d-none':''}}">
                            <td>{{item['sysDate'] | date}}</td>
                            <td class="paymentLink"><a (click)="openPaymentModal(item['pid'],item)"
                                    class="mouse-pointerz">{{item['pid']}}</a>
                            </td>
                            <td>{{this.accountService.getTitleForHistory(item)}}</td>
                            <td class="text-right">



                                {{

                                this.accountService.getFormattedAmount(item?.amount)

                                }}


                            </td>
                            <td class="text-right">{{
                                
                            
                                this.accountService.getFormattedAmount(item?.totSaveAmt)
                            }}</td>
                            <td class="text-right">{{this.accountService.getFormattedAmount(item?.paybackAmount)}}</td>
                            <td class="text-right">{{
                                
                            
                                this.accountService.getFormattedAmount(item?.balToDone)
                            }}</td>
                        </tr>
                        <tr class="text-center">
                            <td colspan="7">No more payment(s) to display</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!this.trxnDetails?.RevTrxnDetail?.pymt?.RevPymtDetail" class="text-center">
                        <tr>
                            <td colspan="7">
                                <span *ngIf="isTransactionDetailsLoaded">
                                    No Payment(s) to display
                                </span>
                                <span *ngIf="!isTransactionDetailsLoaded">
                                    <div class="loader xs-loader mx-auto"></div> Retrieving Payment(s)...
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
                </tr>
                </ng-container>
                <tr class="text-center" *ngIf="isTransactionsLoaded">
                    <td colspan="6">No more transaction(s) to display</td>
                </tr>

                </tbody>
                </nz-table>
            </div>
        </div>
        <div class="mobile_view paymentsTable-mob d-xl-none d-sm d-md-none d-lg-none">
            <section id="trans_his">

                <ng-container *ngIf="this.currentTransactions.length">
                    <div class="form-group m-3 tabel-padding" >
                        <div class="icon-addon addon-lg">
                            <label for="search" class="fa fa-search" rel="tooltip" title="Search..." *ngIf="!searchItem"></label>
                            <input type="text" id="search" placeholder="Search..." class="form-control search-box"  [(ngModel)]="searchItem">
                            <label class="fa fa-close mouse-pointerz"  (click)="searchItem=null" *ngIf="searchItem"></label>
                        </div>
                       
                    </div>
                </ng-container>

                <div class="card shadow mb-3" *ngFor="let transaction of currentTransactions" (scroll)="scroll($event)">
                    <table class="table">
                        <tr>
                            <td class="border-0" style="max-width: 200px;">
                                <p class="font-weight-bold">{{transaction.trxn['cpty'].name}}</p>
                            </td>
                            <td class="border-0">{{
                                this.accountService.getFormattedAmount(transaction.trxn.amount)
                            }}
                            </td>
                        </tr>
                        <tr>
                            <td class="border-0" colspan="2" style="padding: 0 !important;">
                                <nz-collapse [nzBordered]="false" [nzGhost]="true" [nzExpandIconPosition]="'right'">
                                    <nz-collapse-panel class="border-0 mutedText indicator-bar"
                                        [ngClass]="indicator? 'success' : 'fail'"
                                        [nzHeader]='transaction.trxn.recDate +" &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; "  +  transactionStatus[transaction.trxn.status]'
                                        [nzActive]="transaction.trxn.active" [nzDisabled]="transaction.trxn.disabled">
                                        <p style="margin:0;">
                                            <button nz-button nzType="primary" class="primaryPaymentBtn"
                                                (click)="openTransactionModal(transaction.trxn.id,transaction.trxn)">
                                                View Allocation
                                            </button>
                                        </p>
                                    </nz-collapse-panel>
                                </nz-collapse>

                            </td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="isTransactionsLoaded">
                    <div class="d-flex justify-content-center p-5 border-0">
                        <b>{{'No more transaction(s) to display'}} </b>
                    </div>
                </div>
            </section>
        </div>

        <div *ngIf="!isTransactionsLoaded || (filteredTransactions && filteredTransactions.length===0)">
            <div class="d-flex justify-content-center p-5 border-0">
                <b>{{fetchingTransactions ? filteredTransactions.length===0 ? 'Retrieving transactions...' : 'Loading
                    next
                    20 transactions.': 'Retrieving transactions...'}} </b>
            </div>
        </div>
    </div>

       <div class="container" *ngIf="refreshRequired">
            <h3>Session Expired</h3>
            <h5> Please refresh to continue.</h5>
        </div>
</section>