import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { }

  get isLoggedIn(): boolean {
    console.log("Auth Service isLoggedIn");
    return window.localStorage.getItem('token')!=null
  }
 
}
