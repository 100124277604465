<section class="reset-pwd-page verifyMFAWrapper">
  <div class="reset-pwd-wrapper">
    <div class="reset-pwd card">
      <div class="section1">
        <div class="logoImgBox">
          <img src="../../../assets/new-dash/logo/revenuedLogo_black.png" class="logoImg" alt="Revenued Logo" />
        </div>
        <div class="formBox">
          <h1 class="welcomeTitle">
            Verify Your Identity?
          </h1>
          <p class="welcomePara">
            Enter the verification code sent to your email address
          </p>

          <form [formGroup]="otpForm" class="otp-container-wrapper" (ngSubmit)="submitOtp()">
            <div class="otp-container">

              <mat-form-field appearance="outline" class="otpInput myMatFormFieldCustom" subscriptSizing="dynamic"
                *ngFor="let control of ['otp1', 'otp2', 'otp3', 'otp4', 'otp5', 'otp6']; let i = index;">
                <input matInput type="text" maxlength="1" formControlName="{{control}}" #otpInput
                  (keyup)="onKeyUp($event, i)" (paste)="onPaste($event)" inputmode="numeric">
              </mat-form-field>
            </div>
            <mat-hint class="red-hint" *ngIf="isAnyControlInvalid">Only numbers are allowed</mat-hint>


            <!-- Error Message Display -->
            <mat-error *ngIf="errorMessage" class="mainError mt-2 mb-3">{{ errorMessage }}</mat-error>

            <mat-checkbox class="trustDeviceCheckbox" *ngIf="false">
              Trust this device for future logins
            </mat-checkbox>
            <div>
              <button class="primary-general-btn w-100" matRipple [disabled]="otpForm.invalid">
                <div *ngIf="(verified && otpForm.valid) ; else generalCondition">
                  <app-loader></app-loader>
                </div>
                <ng-template #generalCondition>
                  <span>Verify</span>
                </ng-template>
              </button>


            </div>
          </form>
          <div class="otp-resend">
            <span>Didn't receive the code? </span>
            <a href="javascript:void(0);" class="link-items" (click)="resendOtp()" [class.disabled]="isResendDisabled">
              {{ isResendDisabled ? 'Resend in ' + timer + 's' : 'Resend Code' }}
            </a>
          </div>

          <div class="bottom-action-links">
            <a href="javascript:void(0);" routerLink="/login" class="link-items">
              <figure>
                <!-- <img src="../../../assets/new-dash/login/backArrow.svg" alt="Back to Sign-In icon" /> -->
                <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="19.371" height="11.662"
                  viewBox="0 0 19.371 11.662">
                  <path id="Path_53" data-name="Path 53"
                    d="M8.489,8.941a.8.8,0,0,1,0,1.135L4.811,13.754h15.82a.8.8,0,1,1,0,1.6H4.827l3.662,3.654a.8.8,0,1,1-1.127,1.127L2.276,15.049a.7.7,0,0,1,0-1.015L7.353,8.941a.8.8,0,0,1,1.135,0Z"
                    transform="translate(-2.06 -8.704)" fill="#62cdf2" />
                </svg>

              </figure>
              Cancel
            </a>
          </div>
        </div>
      </div>
      <div class="section2">
        <div class="inner-div">
          <!-- <p class="content">
            A card that cares less about FICO and more about your business
          </p> -->
          <figure class="m-0">
            <img src="../../../assets/new-dash/login/createPwd.webp" alt="Verify MFA" />
          </figure>
        </div>
      </div>
    </div>
    <footer class="footer--forgotPwd">
      <div class="footerMenu">
        <a href="https://www.revenued.com/about/" class="footerMenuLink" target="_blank">
          About Revenued
        </a>
        <a href="https://www.revenued.com/contact/" class="footerMenuLink" target="_blank">
          Contact Us
        </a>
        <a href="https://www.revenued.com/revenued-cardholder-agreement/" class="footerMenuLink" target="_blank">
          Card User Agreement
        </a>
      </div>
    </footer>
  </div>
  <!-- <div class="bgImage"></div> -->
</section>