<section class="login-page">
  <div class="login-page-wrapper">
    <div class="login-card card">
      <div class="section1">
        <div class="logoImgBox">
          <img src="../../../assets/new-dash/logo/revenuedLogo_black.png" class="logoImg" alt="Revenued Logo" />
        </div>
        <div class="formBox">
          <h1 class="welcomeTitle">
            Hello! Welcome back.
          </h1>
          <form [formGroup]="loginForm" id="form" (ngSubmit)="sendMFACode()">
            <mat-form-field appearance="outline" class="myMatFormField">
              <!-- Add 'invalid' to the class of mat-form-field for invalid inputs -->
              <!-- <mat-label [class.field-focus]="isEmailFocussed"></mat-label> -->

              <img matPrefix src="../../../assets/new-dash/login/emailIcon.png" class="iconBox" alt="Email icon" />
              <input matInput placeholder="Email Address" formControlName="email" type="email" required
                name="Email Address" [readonly]="loggedIn" (focus)="emailOnFocus()" (focusout)="emailOnFocusOut()"
                aria-label="Please enter your email address. This is a required field.">
              <mat-error *ngIf="email?.invalid">{{getEmailErrorMessage()}}
              </mat-error>
              <!-- <mat-hint class="help-text">Please enter your email address</mat-hint> -->
            </mat-form-field>

            <mat-form-field appearance="outline" class="myMatFormField">
              <!-- Add 'invalid' to the class of mat-form-field for invalid inputs -->
              <!-- <mat-label [class.field-focus]="isPwdFocussed"></mat-label> -->
              <img matPrefix src="../../../assets/new-dash/login/pwdIcon.svg" class="iconBox" alt="Password icon" />
              <input matInput placeholder="Password" formControlName="password"
                [type]="fieldTextType ? 'text' : 'password'" [readonly]="loggedIn" required (focus)="pwdOnFocus()"
                (focusout)="pwdOnFocusOut()" aria-label="Please enter your password. This is a required field.">
              <i matSuffix class="fa mouse-pointerz iconBoxSuffix" [ngClass]="{
                  'fa-eye-slash': !fieldTextType,
                  'fa-eye': fieldTextType
                }" (click)="toggleFieldTextType()"></i>
              <mat-error *ngIf="password?.invalid">{{getPwdErrorMessage()}}
              </mat-error>
              <!-- <mat-hint class="help-text">Please enter your password</mat-hint> -->
            </mat-form-field>

            <!-- <mat-error *ngIf="!loginForm.valid">Invalid credentials entered</mat-error> -->
            <div>
              <button class="primary-general-btn w-100" [disabled]="!loginForm.valid" matRipple>
                <div *ngIf="(loggedIn && loginForm.valid); else generalCondition">
                  <app-loader></app-loader>
                </div>
                <ng-template #generalCondition>
                  <span>Login</span>
                </ng-template>
              </button>
              <mat-error *ngIf="loginError" class="mainError">Username and/or password incorrect</mat-error>
            </div>
          </form>
          <div class="bottom-action-links">
            <a href="javascript:void(0);" routerLink="/forgot-password" class="link-items">
              Forgot Password?
            </a>
            <a href="javascript:void(0);" routerLink="/verify-identity" class="link-items" *ngIf="false">
              Verify Identity
            </a>
            <a href="javascript:void(0);" routerLink="/verify-code" class="link-items otpLogin" *ngIf="false">
              Using MFA Code?
            </a>
          </div>
        </div>
      </div>
      <div class="section2">
        <div class="inner-div">
          <!-- <p class="content">
            A card that cares less about FICO and more about your business
          </p> -->
        </div>
      </div>
    </div>
    <footer class="footer--login">
      <div class="footerMenu">
        <a href="https://www.revenued.com/about/" class="footerMenuLink" target="_blank">
          About Revenued
        </a>
        <a href="https://www.revenued.com/contact/" class="footerMenuLink" target="_blank">
          Contact Us
        </a>
        <a href="https://www.revenued.com/revenued-cardholder-agreement/" class="footerMenuLink" target="_blank">
          Card User Agreement
        </a>
      </div>
    </footer>
  </div>
  <!-- <div class="bgImage"></div> -->
</section>