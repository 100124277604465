<div class="modal-header">
    <h4 class="modal-title">Payment Details</h4>
    <button type="button" class="btn-close" (click)="activeModal.dismiss('Cross click')"><span
            aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body">
    <div class="container">
        <div id="paymentModalContent" *ngIf="this.pymtDetails">


            <h5> {{this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail[0]?.pid?('Payment ID:
                '+this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail[0]?.pid):''}}
            </h5>


            <ng-container *ngIf="this.pymtDetails?.RevPayment">
                <table class="table">
                    <tr>
                        <td class="border-0" style="min-width:400px !important;padding: 0% !important;">

                            <p class="d-flex">
                                {{this.pymtDetails?.RevPayment?.notes}}
                            </p>
                        </td>
                        <td class="border-0" style="min-width:400px !important;padding: 0% !important;">
                            <p class="font-weight-bold">
                                {{
                                this.accountService.getFormattedAmount(this.pymtDetails?.RevPayment?.amount)
                            }}</p>
                        </td>
                    </tr>
                    <tr>

                        <td class="border-0" style="min-width:400px !important;padding: 0% !important;">
                            {{this.pymtDetails?.RevPayment?.sysDate | date}}
                        </td>

                        <td class="border-0" style="min-width:400px !important;padding: 0% !important;">
                            <p class="font-weight-bold">
                                {{this.accountService.getTitleForHistory(this.pymtDetails?.RevPayment)}}
                            </p>

                        </td>
                    </tr>

                    <!-- <tr>
                        <td class="border-0" colspan="2"
                            style="min-width:400px !important;padding: 0% !important;">
                          
        
                           <strong> Total Number of Transactions:
                            {{this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail.length}}
                        </strong>
                        </td>
                    </tr> -->

                </table>
                <hr>
            </ng-container>

            
            <ng-container *ngIf="this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail.length">
                <h5 ><u>Payments allocated:</u></h5>
                <div class="form-group mr-1 p-2" >
                    <div class="icon-addon addon-lg">
                        <label for="search" class="fa fa-search" rel="tooltip" title="Search..." *ngIf="!searchItem"></label>
                        <input type="text" id="search" placeholder="Search..." class="form-control search-box"  [(ngModel)]="searchItem">
                        <label class="fa fa-close mouse-pointerz"  (click)="searchItem=null" *ngIf="searchItem"></label>
                    </div>
                   
                </div>
            </ng-container>

            <div class="paymentModalCard card shadow mb-3  {{!this.accountService.checkIsVisible(item)?'d-none':''}}"
                *ngFor="let item of this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail">
                <table class="table">
                    <tr>
                        <td style="max-width: 200px; width: 90%; border-color: transparent;color: #2373b0; font-weight: bold;"
                            valign="top">
                            <p class="m-0">
                                Transaction ID:
                                <!-- (click)="showTransactionModal(item['tid'])" -->
                                <a class="mouse-pointerz" (click)="openTransactionModal(item['tid'],item)">{{item['tid']}}</a>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{item.sysDate | date}} <span class="m-0 paymentStatus">
                                <span [ngClass]="indicator? 'success' : 'fail' " class="indicator"></span>
                                {{this.accountService.getTitleForHistory(item)}}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="pt-0 border-0">
                            <hr class="m-0" />
                            <table class="table">
                                <tr>
                                    <td style="width: 50%;border-color: transparent;padding-right: 2px;" valign="top">
                                        <p class="m-0 mutedText">Transaction Amount</p>
                                        <p class="m-0">{{
                                            this.accountService.getFormattedAmount(item.fundedAmount)
                                        }}</p>
                                    </td>
                                    
                                    <td style="width: 50%;border-color: transparent; padding-left:2px;" valign="top">
                                        <p class="m-0 mutedText">Discount Savings</p>
                                        <p class="m-0">{{
                                            this.accountService.getFormattedAmount(item?.totSaveAmt)
                                        }}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td  colspan="2" style="width: 100%; border-color: transparent;padding-right: 2px;" valign="top">
                                        <p class="m-0 mutedText">Payment Allocation</p>
                                        <p class="m-0">{{
                                            
                                            this.accountService.getFormattedAmount(item?.amount)
                                        }}</p>
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td   style="width: 50%;border-color: transparent;padding-right: 2px;" valign="top">
                                        <p class="m-0 mutedText"><small>Starting Bal to Done</small></p>
                                        <p class="m-0">{{
                                            this.accountService.getFormattedAmount(item?.paybackAmount)
                                        }}</p>
                                    </td>
                                    <td   style="width: 50%;border-color: transparent; padding-left:2px;" valign="top">
                                        <p class="m-0 mutedText"><small>End Bal. to Done</small> </p>
                                        <p class="m-0">{{
                                        
                                            this.accountService.getFormattedAmount(item?.balToDone)
                                        }}</p>
                                    </td>
                                </tr>
                               
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
            <div *ngIf="this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail" class="text-center">
                No more transaction(s) to display
            </div>
            <div *ngIf="!this.pymtDetails?.RevPayment?.pymt?.RevPymtDetail" class="text-center">
                <span *ngIf="isTransactionDetailsLoaded">
                    No Transaction(s) to display
                </span>
                <span *ngIf="!isTransactionDetailsLoaded">
                    <div class="loader xs-loader mx-auto"></div>
                    Retrieving Transaction(s)...
                </span>
            </div>
        </div>

    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-lg btn-outline-dark w-100"
        (click)="activeModal.close('Close click')">Close</button>
</div>