import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-errorspage',
  templateUrl: './errorspage.component.html',
  styleUrls: ['./errorspage.component.scss'],
})
export class ErrorspageComponent  implements OnInit {

  code : any;
  constructor(private router: Router, private elementRef: ElementRef ,private route: ActivatedRoute) {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
  }

  ngOnInit(): void {
    
    this.route.params.subscribe(params => {
      this.code = params['code'];
      console.log('Error Code:', this.code); 
    });

  }
  clearData() {
    this.router.navigate(["/login"]).then((res) => {
      localStorage.clear();
    });
  }
}
