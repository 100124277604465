import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { sub } from 'date-fns';
import { id } from 'date-fns/locale';
import { AccountService } from 'src/app/core/service/account.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationsComponent implements OnInit {
  filtersFormGroup: any;
  searchBar: any;
  fromDate: any;
  toDate: any;
  pageSize: number = 10;  
  notifications: any = []
  notificationCountNew!: number;
  loaderActiveFlag: boolean = true;
  @Output() notificationCountEvent = new EventEmitter<number>();

  notificationsListObj: any =[]
  filteredNotifications: any[] = [];   
  currentPageIndex  = 1;
  constructor(
    private sanitizer: DomSanitizer,
    public accountService: AccountService
  ) {
    this.searchBar = new FormControl('');
    this.fromDate = new FormControl(null);
    this.toDate = new FormControl(null);
    this.filtersFormGroup = new FormGroup({
      searchBar: this.searchBar,
      fromDate:this.fromDate ,
      toDate: this.toDate
    });
  }

  ngOnInit() {
    this.loadNotifications();
    this.fetchNotifications();
    this.filtersFormGroup.valueChanges.subscribe(() => {
      this.applyFilters();
    });
  }

  loadNotifications(): void {
    this.accountService.getAllNotifications().subscribe({
      next: (res: any) => {
        const parsedNotifications = JSON.parse(res).map((notification: any) => ({
          title: notification.title,
          subtitleContent: notification.body,
          dateTime: new Date(notification.date).toLocaleString(),
          new: !notification.read,
          timestamp: new Date(notification.date).getTime()
        }));

        this.notifications = parsedNotifications;
          this.handlePageIndexChange({ pageIndex: this.currentPageIndex - 1, pageSize: this.pageSize });
           // this.filteredNotifications = this.notifications.slice(0, 10);
        this.loaderActiveFlag = false,
          // console.log(this.notifications);

          this.notificationCountNew = this.notifications.filter((n: any) => n.new).length;
        this.notificationCountEvent.emit(this.notificationCountNew);

      },
      error: (err: any) => {
        console.error('Error fetching notifications:', err);
        this.loaderActiveFlag = false;
      },
    });
  }

  applyFilters(): void {
    let filtered = [...this.notifications];

    const searchValue: string = this.searchBar.value?.trim().toLowerCase();
    if (searchValue) {
      filtered = filtered.filter(notification =>
        notification.title.toLowerCase().includes(searchValue) ||
        notification.subtitleContent.toLowerCase().includes(searchValue)
      );
    }

    const fromDateValue = this.fromDate.value;
    if (fromDateValue) {
      const fromTimestamp = new Date(fromDateValue).getTime();
      filtered = filtered.filter(notification => notification.timestamp >= fromTimestamp);
    }
    const toDateValue = this.toDate.value;
    if (toDateValue) {
      const toDateObj = new Date(toDateValue);
      toDateObj.setHours(23, 59, 59, 999);
      const toTimestamp = toDateObj.getTime();
      filtered = filtered.filter(notification => notification.timestamp <= toTimestamp);
    }


    this.filteredNotifications = filtered;
  }


  fetchNotifications() {
    this.accountService.getUnreadFlyNotifications().subscribe({
      next: (res: any) => {
        // console.log(res)
      }, error: (err: any) => { }
    })
  }
  
  handlePageIndexChange(event: any): void {
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex = startIndex + event.pageSize;
    this.filteredNotifications = this.notifications.slice(startIndex, endIndex);
    this.currentPageIndex = event.pageIndex;
  }
  

  ngAfterViewChecked() {
    // Add our primary button class to all CTA links
    const notificationCTA = document.querySelectorAll('.notificationsPage .notificationsListWrapper .notificationsListItem .subtitle .description a');
    console.log("NotificationCTA: " + notificationCTA.length);
    if (notificationCTA.length === 0) return;

    notificationCTA.forEach((cta) => {
      const htmlElement = cta as HTMLElement;
      console.log("NotificationCTA: " + htmlElement);
      htmlElement.classList.add('primary-general-btn');
    });
  }

}
