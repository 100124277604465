import { Component, ElementRef, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { AuthService } from "../auth-layout/auth/auth.service";
import { Network } from '@capacitor/network';

@Component({
  selector: "app-no-internet",
  templateUrl: "./no-internet.component.html",
  styleUrls: ["./no-internet.component.css", "../errors-page/errorspage/errorspage.component.scss"],
})
export class NoInternetComponent implements OnInit {
  isRetrying = false;

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private authenticationService: AuthService,
    private toastr: ToastrService
  ) {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = "#fff";
  }


  async retry() {
    this.isRetrying = true;
    // Show retrying message (optional)
    this.toastr.show("Retrying...");

    setTimeout(() => {
      this.isRetrying = false;
    }, 5000);

    // Check network connection after retry
    const status = await Network.getStatus();
    if (status.connected) {
      // Navigate to login or dashboard based on the user's authentication status
      if (!this.authenticationService.isLoggedIn) {
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['/']);
        // this.router.navigate(['/dashboard']);
      }
    } else {
      // Stay on the current page if still offline
      // this.toastr.error("No internet connection");
    }
  }

  // Listen for network status changes in real-time
  ngOnInit() {
    Network.addListener('networkStatusChange', (status) => {
      if (status.connected) {
        if (!this.authenticationService.isLoggedIn) {
          this.router.navigate(['/']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      } else {
        // Do nothing or show a message
        // this.toastr.error("No internet connection");
      }
    });
  }
}
